import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./views/home";
import Login from "./views/login";
import Notfound from "./views/notfound";
import CreateDate from "./views/createDate";
import NumbersLottery from "./views/numbersLottery";
import Setting from "./views/setting";
import Report from "./views/report";
import Logout from "./views/logout";

import { positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import ViewReport from "./views/view-report";
import Test from "./views/test";
import Sell from "./views/v2/Sell";
import Customer from "./views/v2/Customer";
import Dashboard from "./views/v2/Dashboard";
import NumberLimit from "./views/v2/NumberLimit";

import { Provider as ReduxProvider } from "react-redux";
import store from "./redux/store";
import Bill from "./views/v2/Bill";
import FormCustomer from "./views/v2/FormCustomer";
import Report1 from "./views/v2/Report1";
import PriceLimit from "./views/v2/PriceLimit";
import CreatePriceLimit from "./views/v2/CreatePriceLimit";
import Report2 from "./views/v2/Report2";
import ReportCustomer from "./views/v2/ReportCustomer";
import Sell2 from "./views/v2/Sell2";
import Result from "./views/v2/Result";
import Report3 from "./views/v2/Report3";

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER,
};

ReactDOM.render(
  <ReduxProvider store={store}>
    <AlertProvider template={AlertTemplate} {...options}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="home" element={<Home />} />
          <Route path="/create-date" element={<CreateDate />} />
          <Route path="/numbers/:id" element={<NumbersLottery />} />

          <Route path="/report/:id/:date" element={<Report />} />
          <Route path="/logout" element={<Logout />} />

          <Route path="/test" element={<Test />} />

          <Route
            path="/view-report/:id/:date/:name/:token"
            element={<ViewReport />}
          />

          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/sell" element={<Sell />} />
          <Route path="/sell/:id" element={<Sell />} />

          <Route path="/sell2/:cusID/:cusName/:dateID/:token" element={<Sell2 />} />

          <Route path="/bill" element={<Bill />} />
          <Route path="/price-limit" element={<PriceLimit />} />
          <Route path="/create-price-limit" element={<CreatePriceLimit />} />
          <Route path="/report1" element={<Report1 />} />
          <Route path="/report2" element={<Report3 />} />
          <Route path="/report-customer" element={<ReportCustomer />} />

          <Route path="/customer" element={<Customer />} />
          <Route path="/form-customer" element={<FormCustomer />} />
          <Route path="/form-customer/:id" element={<FormCustomer />} />

          <Route path="/settings" element={<Setting />} />
          <Route path="/number-limit" element={<NumberLimit />} />
          <Route path="/result" element={<Result />} />

          <Route path="*" element={<Notfound />} />
        </Routes>
      </BrowserRouter>
    </AlertProvider>
  </ReduxProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

serviceWorkerRegistration.register();
reportWebVitals();
