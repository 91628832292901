import Layout from "../components/Layout";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../config";
import { useParams } from "react-router-dom";
import TableComponent from "../components/TableComponent";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAlert } from "react-alert";
const Report = () => {
  const [token, setToken] = useState("");
  const [datas, setDatas] = useState([]);
  const [displayName, setDisplayName] = useState("");
  const [total, setTotal] = useState("");
  const [text, setText] = useState("");
  let params = useParams();
  const alert = useAlert();

  const getDate = async () => {
    var config = {
      method: "get",
      url: `${BASE_URL}/report?dateID=${params.id}`,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status == "ok") {
          setDatas(response.data.data);
          setTotal(response.data.total);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    let token = localStorage.getItem("token");
    let name = localStorage.getItem("name");
    if (token) {
      setToken(token);
    }
    if (name) {
      setDisplayName(name);
    }
  }, []);
  function encodeFunc(string) {
    const codeUnits = new Uint16Array(string.length);
    for (let i = 0; i < codeUnits.length; i++) {
      codeUnits[i] = string.charCodeAt(i);
    }
    return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
  }
  useEffect(() => {
    if (token != "") {
      getDate();

      let dname = encodeFunc(displayName);
      let txt =
        process.env.REACT_APP_URL +
        `/view-report/${params.id}/${params.date}/${dname}/${token}`;
      setText(txt);
    }
  }, [token]);

  return (
    <Layout>
      <div className="row">
        <div style={{ textAlign: "right" }}>
          {/* <button
            className="btnPrint btn btn-primary mt-3 mb-3"
            onClick={() => copyLink()}
          >
            Copy Link
          </button>{" "} */}
          <CopyToClipboard text={text}>
            <button
              className="btn btn-primary"
              onClick={() => alert.success("คัดลอกสำเร็จ")}
            >
              คัดลอก Link
            </button>
          </CopyToClipboard>{" "}
          &nbsp;
          <button
            className="btnPrint btn btn-secondary mt-3 mb-3"
            onClick={() => window.print()}
          >
            <i className="fa fa-print"></i> Print
          </button>
        </div>
        <div className="print-header color-white">
          วันที่: {atob(params.date)} ชื่อ: {displayName}
        </div>
        <div className="col-6" style={{ paddingRight: "0px" }}>
          {" "}
          <TableComponent
            label={"บน"}
            type={1}
            datas={datas}
            min={"66"}
            max={"99"}
            breakPage={false}
            total={total != "" ? total.total1 : ""}
          />
        </div>
        <div className="col-6" style={{ paddingLeft: "0px" }}>
          <TableComponent
            label={"ล่าง"}
            type={2}
            datas={datas}
            min={"66"}
            max={"99"}
            breakPage={false}
            total={total != "" ? total.total2 : ""}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Report;
