import http from "./http-common";

const find = ({ search }) => {
  return http.get(`/customer?search=${search || ""}`);
};
const create = (data) => {
  return http.post("/customer", data);
};

const report = ({ search }) => {
  let dateID = localStorage.getItem("dateID");
  return http.get(`/customer/report?dateID=${dateID}&search=${search || ""}`);
};

const findById = ({ id }) => {
  return http.get(`/customer/${id}`);
};
const customerService = {
  find,
  findById,
  create,
  report,
};

export default customerService;
