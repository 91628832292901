import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../config";
import ModalPrice from "./ModalPrice";

const Number = ({ type }) => {
  let params = useParams();
  const [token, setToken] = useState("");
  const [datas, setDatas] = useState([]);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});

  const [selectNumberID, setSelectNumberID] = useState("");
  const getNumbers = (id) => {
    var config = {
      method: "get",
      url: `${BASE_URL}/number?dateID=${id}&type=${type}`,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status == "ok") {
          setDatas(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (token && params.id) {
      getNumbers(params.id);
    }
  }, [params.id, token]);
  //init data
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      setToken(token);
    }
  });
  return (
    <div className="row">
      <div className="col-12" style={{ zIndex: "10000" }}>
        {show == true && (
          <ModalPrice
            selectNumberID={selectNumberID}
            token={token}
            type={type}
            data={data}
            numbers={datas}
            setSelectNumberID={setSelectNumberID}
          />
        )}
      </div>

      {datas.length > 0 &&
        datas.map((i, index) => (
          <div
            key={index}
            className="col-4 mt-3 color-white"
            onClick={() => {
              setSelectNumberID(i.id);
              setShow(true);
              setData(i);
            }}
          >
            {/* {i.id} {selectNumberID} */}
            <div className={"card"}>
              <div
                className={
                  i.id == selectNumberID
                    ? "card-active"
                    : "" + " card-body text-center card-number"
                }
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  display: "flxe",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100px",
                  display: "flex",
                  cursor: "pointer",
                }}
              >
                <a> {i.number}</a>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Number;
