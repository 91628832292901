import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { BASE_URL } from "../config";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../components/v2/Layout";

import { useAlert } from "react-alert";
import dayjs from "dayjs";
import { WatDatePicker } from "thaidatepicker-react";

const CreateDate = () => {
  const [startDate, setStartDate] = useState(new Date());
  const alert = useAlert();
  let navigate = useNavigate();
  const [token, setToken] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [selectedDate, setSelectedDate] = useState(new Date()); //

  const handleWatDatePickerChange = (christDate, buddhistDate) => {
    setSelectedDate(christDate);
    onSubmit(christDate);
  };
  const onSubmit = (createDate) => {
    localStorage.setItem("createDate", createDate);
    var formData = new FormData();
    formData.append("createDate", createDate);
    var config = {
      method: "post",
      url: `${BASE_URL}/dates`,
      headers: {
        Authorization: "Bearer " + token,
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status == "ok") {
          localStorage.setItem("dateID", response.data.data);
          alert.success(response.data.message);
          navigate("/home");
        } else {
          alert.error(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        alert.error("เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้งคะ");
      });
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      setToken(token);
    } else {
      navigate("/login");
    }

    let dateID = localStorage.getItem("dateID");
    if (dateID) {
      navigate("/home");
    }
  }, []);
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-date">
          <label htmlFor="createDate">เลือกวันที่เข้าทำงาน </label>
          <WatDatePicker
            className="form-control"
            value={selectedDate} // Can be replace with string or dayjs object (e.g. "2020-12-31" or `dayjs()`)
            onChange={handleWatDatePickerChange}
            dateFormat={"yyyy-MM-dd"} // for set data purpose [using date-fns format](https://date-fns.org/v2.12.0/docs/format)
            displayFormat={"dddd, DD MMMM YY"} // for display purpose (using dayjs format)[https://day.js.org/docs/en/display/format]
            inputStyle={{
              color: "rgb(250 251 255)",
              background: "#03a9f4",
              padding: "10px",
            }} // styles for input
            clearable={true} // true | false
            minDate={"2020-12-26"} // supported date as string
            maxDate={dayjs().add(20, "day")} // also supported dayjs or moment
            disabled={false} // true | false
            readOnly={false} // true | false
            yearBoundary={99} // number of boundary ±X Year
          />
        </div>
      </form>
    </div>
  );
};

export default CreateDate;
