import React, { useState } from "react";
const ModalSell = ({ setCustomNumber }) => {
  const [list, setList] = useState("");
  const [type, setType] = useState(null);
  const [msg, setMsg] = useState("");
  const onSubmit = () => {
    let numberArr = list.split("=");
    let number = numberArr[0]; //เลข
    let numberStr = "";
    let n2 = number.split(""); //บน ล่าง
    if (type == 1) {
      numberStr += `${n2[0]}${n2[1]}${n2[2]}=${numberArr[1]}\n`; //012
      numberStr += `${n2[1]}${n2[2]}${n2[0]}=${numberArr[1]}\n`; //120
      numberStr += `${n2[2]}${n2[0]}${n2[1]}=${numberArr[1]}`; //102
      document.getElementById("modal-closed").click();
    }
    if (type == 2) {
      if (
        n2[0] == n2[1] ||
        n2[0] == n2[2] ||
        n2[1] == n2[0] ||
        n2[1] == n2[2] ||
        n2[2] == n2[0] ||
        n2[2] == n2[1]
      ) {
        setMsg("รูปแบบไม่ถูกต้อง เลขต้องไม่ซ้ำกัน");
      } else {
        numberStr += `${n2[0]}${n2[1]}${n2[2]}=${numberArr[1]}\n`;
        numberStr += `${n2[0]}${n2[2]}${n2[1]}=${numberArr[1]}\n`;
        numberStr += `${n2[1]}${n2[0]}${n2[2]}=${numberArr[1]}\n`;
        numberStr += `${n2[1]}${n2[2]}${n2[0]}=${numberArr[1]}\n`;
        numberStr += `${n2[2]}${n2[0]}${n2[1]}=${numberArr[1]}\n`;
        numberStr += `${n2[2]}${n2[1]}${n2[0]}=${numberArr[1]}`;

        document.getElementById("modal-closed").click();
      }
    }
    setCustomNumber(numberStr);
    setList("");
  };
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="text-white">กรอกเลข 3 ตัวกลับ 6 ตัวกลับ</div>
            <div>
              <div className="mt-3">
                <div>
                  <label htmlFor="list">
                    หมายเลข &nbsp;&nbsp;
                    <span>
                      <label htmlFor="type1">
                        <input
                          type="radio"
                          value="1"
                          id="type1"
                          name="type"
                          onChange={(e) => setType(e.target.value)}
                        />{" "}
                        3 ตัวกลับ
                      </label>{" "}
                      <label htmlFor="type2">
                        <input
                          type="radio"
                          value="2"
                          id="type2"
                          name="type"
                          onChange={(e) => setType(e.target.value)}
                        />{" "}
                        6 ตัวกลับ
                      </label>
                    </span>
                  </label>
                </div>
                <input
                  type={"text"}
                  className="form-control"
                  id="list"
                  placeholder="123=100*50*20 หมายถึง บน=100 โต๊ด=50 ล่าง=20"
                  onChange={(e) => setList(e.target.value)}
                  value={list}
                />
                {msg ? <div className="alert alert-danger">{msg}</div> : ""}
              </div>

              <div className=" mt-3">
                <button
                  type="button"
                  className="btn btn-primary  rounded-2"
                  onClick={() => onSubmit()}
                >
                  ยืนยัน
                </button>{" "}
                <button
                  type="button"
                  className="btn btn-danger  rounded-2"
                  data-bs-dismiss="modal"
                  id="modal-closed"
                >
                  ปิด
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSell;
