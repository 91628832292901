import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import numberService from "../services/number";
export const getNumbers = createAsyncThunk(
  "numbers/getNumbers",
  async ({ search }) => {
    try {
      let res = await numberService.find({ search: search });
      if (res.data.status == "ok") {
        return res.data.data;
      } else {
        return [];
      }

      return res;
    } catch (error) {
      return error;
    }
  }
);

export const createNumbers = createAsyncThunk(
  "numbers/createNumbers",
  async (formData) => {
    try {
      let res = await numberService.create(formData);
      return res.data;
    } catch (error) {
      return error;
    }
  }
);

export const getNumberById = createAsyncThunk(
  "numbers/getNumberById",
  async ({ cusID, billName, dateID }) => {
    try {
      let res = await numberService.findByID({ cusID, billName, dateID });
      if (res.data.status == "ok") {
        return res.data.data;
      } else {
        return [];
      }
    } catch (error) {
      return error;
    }
  }
);

//total price
export const sumPrice = createAsyncThunk("numbers/sumPrice", async () => {
  try {
    let dateID = localStorage.getItem("dateID");
    let res = await numberService.sumPrice({ dateID });
    if (res.data.status == "ok") {
      return res.data.data.total;
    }
    return 0;
  } catch (error) {
    return error;
  }
});

const numberSlice = createSlice({
  name: "numbers",
  initialState: {
    numbers: [],
    numberDetail: [],
    loading: false,
    error: null,
    statusChange: false,
    total: 0,
  },
  extraReducers: {
    //sum price
    //loading
    [sumPrice.pending]: (state) => {
      state.loading = true;
    },
    //success
    [sumPrice.fulfilled]: (state, action) => {
      state.loading = false;
      state.total = action.payload;
    },
    //error
    [sumPrice.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //loading
    [getNumbers.pending]: (state) => {
      state.loading = true;
    },
    //success
    [getNumbers.fulfilled]: (state, action) => {
      state.loading = false;
      state.numbers = action.payload;
    },
    //error
    [getNumbers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //getNumberById
    //loading
    [getNumberById.pending]: (state) => {
      state.loading = true;
      state.numberDetail = [];
    },
    //success
    [getNumberById.fulfilled]: (state, action) => {
      state.loading = false;
      state.numberDetail = action.payload;
    },
    //error
    [getNumberById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //create
    [createNumbers.pending]: (state) => {
      state.loading = true;
      state.statusChange = false;
    },
    [createNumbers.fulfilled]: (state, action) => {
      state.loading = false;
      state.statusChange = true;
    },
    [createNumbers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.statusChange = false;
    },
  },
});
export default numberSlice.reducer;
