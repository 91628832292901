import http from "./http-common";

const find = ({ search }) => {
  let dateID = localStorage.getItem("dateID");
  return http.get(`/number?dateID=${dateID}&search=${search || ""}`);
};
const findByID = ({ cusID, billName, dateID }) => {
  return http.get(
    `/number/find-one?cusID=${cusID}&billName=${billName}&dateID=${dateID}`
  );
};
const findByDate = ({ dateID }) => {
  return http.get(`/number/find-by-date?dateID=${dateID}`);
};

const findByDate2 = ({ dateID, search }) => {
  return http.get(`/number/find-by-date2?dateID=${dateID}&search=${search}`);
};
const create = (data) => {
  return http.post("/number", data);
};

const sumPrice = ({ dateID }) => {
  return http.get(`/number/sum-price?dateID=${dateID}`);
};

const addResult = ({
  number1,
  number2,
  number3,
  number4,
  number5,
  number6,
}) => {
  let formData = new FormData();
  formData.append("number1", number1);
  formData.append("number2", number2);
  formData.append("number3", number3);
  formData.append("number4", number4);
  formData.append("number5", number5);
  formData.append("number6", number6);
  formData.append("date_id", localStorage.getItem("dateID"));
  return http.post(`/result/add`, formData);
};
const getResult = () => {
  let date_id = localStorage.getItem("dateID");
  return http.get(`/result/get-result?date_id=${date_id}`);
};

const processResult = () => {
  let formData = new FormData();
  formData.append("date_id", localStorage.getItem("dateID"));
  return http.post(`/result/process`, formData);
};

const getResultAll = (ntype='') => {
  let date_id = localStorage.getItem("dateID");
  return http.get(`/result/all?date_id=${date_id}&ntype=${ntype}`);
};
const numberService = {
  create,
  find,
  findByID,
  findByDate,
  findByDate2,
  sumPrice,
  addResult,
  getResult,
  processResult,
  getResultAll
};

export default numberService;
