import http from "./http-common";

const find = ({ search }) => {
  let dateID = localStorage.getItem("dateID");
  return http.get(`/price-limit?dateID=${dateID}&search=${search || ""}`);
};
const create = ({ data }) => {
  return http.post("/price-limit", data);
};
const update = ({ data, id }) => {
  return http.put(`/price-limit/${id}`, data);
};
const remove = ({ id }) => {
  return http.delete(`/price-limit/${id}`);
};
const priceLimitService = {
  create,
  find,
  update,
  remove,
};

export default priceLimitService;
