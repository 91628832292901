import React, { useEffect, useState } from "react";
import Layout from "../../components/v2/Layout";
import { useAlert } from "react-alert";
import { getCustomers } from "../../redux/customerSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Search from "../../components/v2/Search";

const Customer = () => {
  const dispatch = useDispatch();
  const { customers } = useSelector((state) => state);
  let navigate = useNavigate();
  let params = useParams();
  const alert = useAlert();

  useEffect(() => {
    dispatch(getCustomers({ search: "" }));
  }, []);
  const edit = (id) => {
    navigate("/form-customer/" + id);
  };

  //search
  function search(inputValue) {
    dispatch(getCustomers({ search: inputValue }));
  }
  return (
    <Layout>
      <h3>ลูกค้า</h3>
      <div className="row">
        <div className="col-7">
          <Search placeholder={"ค้นหาลูกค้า"} handleSearch={search} />
        </div>
        <div className="col-5 text-end">
          <Link to={`/form-customer`} className="btn btn-success">
            <i className="fa fa-plus" aria-hidden="true"></i> เพิ่มรายการ
          </Link>
        </div>
      </div>
      <div className="card-body mt-3">
        {customers.loading ? (
          <h3 className="text-center">Loading...</h3>
        ) : (
          <div className="table-responsive">
            <table className="table table-responsive table-bordered">
              <thead>
                <tr>
                  <th></th>
                  <th>ชื่อลูกค้า</th>
                  <th>% เลขวิ่ง</th>
                  <th>% เลข 2 ตัว</th>
                  <th>% เลข 3 ตัวตรง</th>
                  <th>% เลข 3 ตัวโต๊ต</th>
                  <th>% เลข 3 ล่าง</th>
                  <th>วิ่งบน</th>
                  <th>วิ่งล่าง</th>
                  <th>2ตัว บน-ล่าง</th>
                  <th>3 ตัวตรง</th>
                  <th>3 ตัวโต๊ต</th>
                  <th>3 ตัวล่าง</th>
                </tr>
              </thead>
              <tbody>
                {customers.customers &&
                  customers.customers.map((c, index) => (
                    <tr key={index}>
                      <td>
                        <button
                          onClick={() => edit(c.id)}
                          className="btn btn-primary btn-sm"
                        >
                          <i className="fa fa-edit"></i>
                        </button>{" "}
                        <button
                          className="btn btn-sm btn-secondary"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${process.env.REACT_APP_URL}/sell2/${c.id}/${
                                c.cusName
                              }/${localStorage.getItem(
                                "dateID"
                              )}/${localStorage.getItem("token")}`
                            );
                            alert.success("คัดลอกลิงค์สำเร็จ");
                          }}
                        >
                          คัดลอกลิงค์
                        </button>
                      </td>
                      <td>{c.cusName}</td>
                      <td>{c.num1}</td>
                      <td>{c.num2}</td>
                      <td>{c.num3}</td>
                      <td>{c.num4}</td>
                      <td>{c.num5}</td>
                      <td>{c.pay1}</td>
                      <td>{c.pay2}</td>
                      <td>{c.pay3}</td>
                      <td>{c.pay4}</td>
                      <td>{c.pay5}</td>
                      <td>{c.pay6}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Customer;
