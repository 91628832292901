import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../config";
import { debounce } from "lodash";

import { useAlert } from "react-alert";
const Config = ({ type }) => {
  const alert = useAlert();
  let params = useParams();
  const [token, setToken] = useState("");
  const [datas, setDatas] = useState([]);
  const [total, setTotal] = useState(null);
  const getConfigs = (id) => {
    var config = {
      method: "get",
      url: `${BASE_URL}/config?id=${id}&type=${type}`,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status == "ok") {
          setDatas(response.data.data);
          setTotal(response.data.total);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    let id = localStorage.getItem("dateID");
    if (token && id) {
      getConfigs(id);
    }
  }, [token]);
  //init data
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      setToken(token);
    }
  },[]);

  const updateDatas = debounce((data, id) => {
    var formData = new FormData();
    formData.append("value", data);
    var config = {
      method: "put",
      url: `${BASE_URL}/config/${id}`,
      headers: {
        Authorization: "Bearer " + token,
      },
      data: formData,
    };
    axios(config)
      .then(function (response) {
        if (response.data.status == "ok") {
          alert.success(response.data.message);
        } else {
          alert.error(response.data.message);
        }
      })
      .catch(function (error) {
        alert.error("เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้งคะ");
      });
  }, 1000);
  return (
    <div className="row">
      <div className="col-12">
        <div className="table-responsive">
          <table className="table table-bordered table-config">
            <thead>
              <tr>
                <th className="text-center">เลข</th>
                <th width="60" className="text-center">
                  ราคาอั้น
                </th>
                <th className="text-center">สถานะ</th>
                <th className="text-center">ยอดรวม</th>
                <th className="text-center">ส่งเจ้า</th>
                <th className="text-center">เหลือ</th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th style={{ whiteSpace: "nowrap" }}>ยอดรวม</th>
                <th style={{ whiteSpace: "nowrap" }}>
                  {total != null && total.total}
                </th>
                <th style={{ whiteSpace: "nowrap" }}>
                  {total != null && total.totalPrice}
                </th>
                <th style={{ whiteSpace: "nowrap" }}>
                  {total != null && total.totalAll}
                </th>
              </tr>
            </thead>
            <tbody>
              {datas.length > 0 &&
                datas.map((i, index) => (
                  <tr key={index}>
                    <td>{i.label}</td>
                    <td>
                      <input
                        type={"text"}
                        defaultValue={i.value}
                        onChange={(e) => updateDatas(e.target.value, i.id)}
                        style={{ width: "60px" }}
                      />
                    </td>
                    <td className="text-center">
                      {i.status == "outoffstock" && (
                        <label className="text-danger">เต็ม</label>
                      )}
                    </td>
                    <td style={{ color: "#efb709" }}>{i.total}</td>
                    <td style={{ color: "#efb709" }}>{i.totalPrice}</td>
                    <td style={{ color: "#efb709" }}>{i.totalAll}</td>
                  </tr>
                ))}
            </tbody>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>ยอดรวม</th>
                <th>{total != null && total.total}</th>
                <th>{total != null && total.totalPrice}</th>
                <th>{total != null && total.totalAll}</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Config;
