import React from "react";
import Header from "./Header";
const Layout2 = ({ children }) => {
  return (
    <div>
      <div className="container mt-1">{children}</div>
    </div>
  );
};

export default Layout2;
