import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL, currencyFormat } from "../config";
import FormPrice from "./FormPrice";
import { useAlert } from "react-alert";
import Sticky from "react-sticky-el";
import Select from "react-select";
import chroma from "chroma-js";
const ModalPrice = ({
  selectNumberID,
  token,
  type,
  data,
  numbers,
  setSelectNumberID,
}) => {
  const [datas, setDatas] = useState([]);
  const [selectID, setSelectID] = useState("");
  const [price, setPrice] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [options, setOption] = useState([]);
  const alert = useAlert();

  const getPriceNumber = (id) => {
    var config = {
      method: "get",
      url: `${BASE_URL}/price-number?id=${id}`,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status == "ok") {
          setDatas(response.data.data);
          let total = 0;
          for (let i of response.data.data) {
            total += parseInt(i.price);
          }

          setTotalPrice(currencyFormat({ number: total, decimal: 0 }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    if (selectNumberID != "") {
      getPriceNumber(selectNumberID);
    }
    if (numbers.length > 0) {
      let values = [];
      for (let i of numbers) {
        values.push({
          label: i.number,
          value: i.id,
          className: "custom-class",
        });
      }
      setOption(values);
    }
  }, [selectNumberID]);

  const handleSubmitFormsuccess = () => {
    getPriceNumber(selectNumberID);
  };

  const deleteFunc = (id) => {
    if (window.confirm("คุณต้องการลบรายการนี้หรือไม่")) {
      var config = {
        method: "delete",
        url: `${BASE_URL}/price-number/${id}`,
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      axios(config)
        .then(function (response) {
          if (response.data.status == "ok") {
            alert.success(response.data.message);
            handleSubmitFormsuccess();
          } else {
            alert.error(response.data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const customFilter = (option, searchText) => {
    if (option.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Sticky>
      <div className="card sticky-top">
        <div className="card-body">
          <h5>
            <div className="row">
              <div className="col-5 color-white">เพิ่มหวย 2 ตัว:</div>
              <div className="col-7 select-2">
                {/* {selectNumberID} */}
                <Select
                   filterOption={customFilter}
                  value={
                    options.filter(
                      (option) => option.value === selectNumberID
                    )[0]
                  }
                  placeholder="เลือก"
                  options={options}
                  onChange={(e) => {
                    setSelectNumberID(e.value);
                    // let dataDefault = options.filter(
                    //   (option) => option.value === selectNumberID
                    // );
                    // console.log(dataDefault[0]);
                    let n = e.label;
                    if (n <= 5) {
                      window.scrollTo(0, 220);
                    } else if (n > 5 && n <= 11) {
                      window.scrollTo(0, 440);
                    } else if (n > 11 && n <= 20) {
                      window.scrollTo(0, 750);
                    } else if (n > 20 && n <= 29) {
                      window.scrollTo(0, 1080);
                    } else if (n > 29 && n <= 38) {
                      window.scrollTo(0, 1400);
                    } else if (n > 39 && n <= 47) {
                      window.scrollTo(0, 1800);
                    } else if (n > 47 && n <= 56) {
                      window.scrollTo(0, 2180);
                    } else if (n > 57 && n <= 65) {
                      window.scrollTo(0, 2550);
                    } else if (n > 65 && n <= 74) {
                      window.scrollTo(0, 2900);
                    } else if (n > 75 && n <= 83) {
                      window.scrollTo(0, 3260);
                    } else if (n > 84 && n <= 92) {
                      window.scrollTo(0, 3600);
                    } else if (n > 93 && n <= 99) {
                      window.scrollTo(0, 4000);
                    }
                  }}
                  style={{
                    background: "rgb(255 180 16)",
                    color: "#000",
                    padding: "0 20px 0 10px",
                  }}
                />
              </div>
            </div>
          </h5>
          <div className="modal-body">
            <FormPrice
              numberID={selectNumberID}
              id={selectID}
              price={price}
              token={token}
              handleSubmitFormsuccess={handleSubmitFormsuccess}
              setPrice={setPrice}
              setSelectID={setSelectID}
            />
            <br />

            <div className="data-list">
              {datas.length > 0 ? (
                datas.map((i, index) => (
                  <div key={index} className="row mt-2 ">
                    <div className="col-6">
                      <label>{i.price}</label>
                    </div>
                    <div
                      className="col-6"
                      style={{ textAlign: "right", whiteSpace: "nowrap" }}
                    >
                      <a
                        className="text-info"
                        onClick={() => {
                          setSelectID(i.id);
                          setPrice(i.price);
                        }}
                      >
                        <i className="fa fa-edit "></i> แก้ไข
                      </a>
                      &nbsp;&nbsp;
                      <a
                        href="#"
                        className="text-danger"
                        onClick={() => deleteFunc(i.id)}
                      >
                        <i className="fa fa-trash"></i> ลบ
                      </a>
                    </div>
                  </div>
                ))
              ) : (
                <div className="container">
                  <h3
                    className="text-center text-warning mt-3 mb-3 "
                    style={{ border: "1px solid", padding: "5px" }}
                  >
                    ไม่พบข้อมูล
                  </h3>
                </div>
              )}
            </div>
            <div className="data-total">
              {datas.length > 0 && (
                <div className="mt-3">
                  <hr />
                  <label>
                    <b>
                      รวมทั้งหมด :{" "}
                      <span style={{ fontSize: "18px", color: "#ffc800" }}>
                        ฿{totalPrice}
                      </span>
                    </b>
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  );
};

export default ModalPrice;
