import React, { useEffect, useState, useRef } from "react";
import Layout from "../../components/v2/Layout";
import Report2Digit from "../../components/v3/Report2Digit";
import Report3Digit from "../../components/v3/Report3Digit";
import Report1Digit from "../../components/v3/Report1Digit";
import Report4Digit from "../../components/v3/Report4Digit";

const Report3 = () => {
  const [menu, setMenu] = useState(4);

  return (
    <Layout>
      <div className="card-body">
        <h1 className="fs-3 text-center">ส่งออก</h1>
        <ul className="nav justify-content-center nav-justified ">
          <li className="nav-item">
            <a
              onClick={() => setMenu(4)}
              className={`nav-link rounded-3 shadow text-nowrap ${
                menu == 4 && "active"
              }`}
              aria-current="page"
              href="#"
            >
              {menu == 4 && <i className="fa fa-newspaper-o"></i>} ทั้งหมด
            </a>
          </li>
          <li className="nav-item">
            <a
              onClick={() => setMenu(2)}
              className={`nav-link rounded-3 shadow text-nowrap ${
                menu == 2 && "active"
              }`}
              aria-current="page"
              href="#"
            >
              เลข 2 ตัว
            </a>
          </li>
          <li className="nav-item">
            <a
              onClick={() => setMenu(3)}
              className={`nav-link rounded-3 text-nowrap shadow ${
                menu == 3 && "active"
              }`}
              href="#"
            >
              เลข 3 ตัว
            </a>
          </li>
          <li className="nav-item">
            <a
              onClick={() => setMenu(1)}
              className={`nav-link rounded-3 text-nowrap shadow ${
                menu == 1 && "active"
              }`}
              href="#"
            >
              เลขวิ่ง
            </a>
          </li>
        </ul>

        <div className="tab-content">
          {menu == 4 && <Report4Digit />}
          {menu == 2 && <Report2Digit />}
          {menu == 3 && <Report3Digit />}
          {menu == 1 && <Report1Digit />}
        </div>
      </div>
    </Layout>
  );
};

export default Report3;
