import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import numberService from "../services/number";

export const getReport2 = createAsyncThunk(
  "report2/getReport2",
  async ({ search }, { rejectWithValue }) => {
    try {
      const dateID = localStorage.getItem("dateID");
      let res = await numberService.findByDate2({ dateID, search });
      if (res.data.status == "ok") {
        return res.data.data;
      } else {
        return rejectWithValue(res.data.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const report2Slice = createSlice({
  name: "report2",
  initialState: {
    results: [],
    loading: false,
    error: null,
  },
  extraReducers: {
    //loading
    [getReport2.pending]: (state) => {
      state.loading = true;
    },
    //success
    [getReport2.fulfilled]: (state, action) => {
      state.loading = false;
      state.results = action.payload;
    },
    //error
    [getReport2.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export default report2Slice.reducer;
