import React, { useEffect, useState } from "react";
import CustomerReport from "../../components/v2/CustomerReport";
import Layout from "../../components/v2/Layout";
import { convertDateTimeTh } from "../../config";

const ReportCustomer = () => {
  const [date, setDate] = useState("");
  useEffect(() => {
    let createDate = localStorage.getItem("createDate");
    if (createDate) {
      console.log(convertDateTimeTh(createDate));
      setDate(convertDateTimeTh(createDate));
    }
  }, []);
  return (
    <Layout>
      <h3>รายงานสรุปรายได้ งวดวันที่ {date}</h3>
      <CustomerReport />
    </Layout>
  );
};

export default ReportCustomer;
