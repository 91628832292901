import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import priceLimit from "../services/priceLimit";

export const getPriceLimit = createAsyncThunk(
  "priceLimit/getPriceLimit",
  async ({ search }, { rejectWithValue }) => {
    try {
      let res = await priceLimit.find({ search });
      if (res.data.status == "ok") {
        return res.data.data;
      } else {
        return [];
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const createPriceLimit = createAsyncThunk(
  "priceLimit/createPriceLimit",
  async ({ data }, { rejectWithValue }) => {
    try {
      let res = await priceLimit.create({ data });
      if (res.data.status == "ok") {
        return res.data.data;
      } else {
        return rejectWithValue(res.data.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const updatePriceLimit = createAsyncThunk(
  "priceLimit/updatePriceLimit",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      let res = await priceLimit.update({ data, id });
      if (res.data.status == "ok") {
        return res.data.data;
      } else {
        return rejectWithValue(res.data.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deletePriceLimit = createAsyncThunk(
  "priceLimit/deletePriceLimit",
  async ({ id }, { rejectWithValue, fulfillWithValue }) => {
    try {
      let res = await priceLimit.remove({ id });
      if (res.data.status == "ok") {
        return fulfillWithValue(true);
      } else {
        return rejectWithValue(res.data.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
const priceLimitSlice = createSlice({
  name: "priceLimit",
  initialState: {
    results: [],
    loading: false,
    error: null,
    status: false,
  },
  extraReducers: {
    //get
    [getPriceLimit.pending]: (state) => {
      state.loading = true;
      state.results = [];
    },
    [getPriceLimit.fulfilled]: (state, action) => {
      state.loading = false;
      state.results = action.payload;
    },
    [getPriceLimit.rejected]: (state, action) => {
      state.loading = false;
      state.results = [];
      state.error = action.payload;
    },

    //create
    [createPriceLimit.pending]: (state) => {
      state.loading = true;
      state.status = false;
    },
    [createPriceLimit.fulfilled]: (state, action) => {
      state.loading = false;
      state.status = true;
    },
    [createPriceLimit.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //update
    [updatePriceLimit.pending]: (state) => {
      state.loading = true;
      state.status = false;
    },
    [updatePriceLimit.fulfilled]: (state, action) => {
      state.loading = false;
      state.status = true;
    },
    [updatePriceLimit.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //delete
    [deletePriceLimit.pending]: (state) => {
      state.loading = true;
      state.status = false;
    },
    [deletePriceLimit.fulfilled]: (state, action) => {
      state.loading = false;
      state.status = true;
    },
    [deletePriceLimit.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export default priceLimitSlice.reducer;
