import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";

import {
  createPriceLimit,
  updatePriceLimit,
  getPriceLimit,
} from "../../redux/priceLimitSlice";
import { useDispatch } from "react-redux";

const PriceLimitForm = ({ defaultValue, setShowForm, setDefaultValue }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (defaultValue) {
      setValue("id", defaultValue.id);
      setValue("number", defaultValue.number || 0);
      setValue("price1", defaultValue.price1 || 0);
      setValue("price2", defaultValue.price2 || 0);
      setValue("price3", defaultValue.price3 || 0);
      setValue("return1", defaultValue.return1 || 0);
    }
  }, [defaultValue]);

  const submit = (data) => {
    let formData = new FormData();
    formData.append("number", data["number"]);
    formData.append("price1", parseInt(data["price1"]) || 0);
    formData.append("price2", parseInt(data["price2"]) || 0);
    formData.append("price3", parseInt(data["price3"]) || 0);
    formData.append("return1", parseInt(data["return1"]) || 0);
    if (data.id) {
      //update
      let id = data.id;
      dispatch(updatePriceLimit({ data: formData, id }))
        .unwrap()
        .then(() => {
          alert.success("เพิ่มข้อมูลสำเร็จ");
          clearForm();
          setShowForm(false);
          setTimeout(() => {
            dispatch(getPriceLimit({ search: "" }));
          }, 100);
        })
        .catch((error) => console.log(error));
    } else {
      if (data.number.length == 3) {
        formData.append("type", 13);
      } else if (data.number.length == 2) {
        formData.append("type", 12);
      } else if (data.number.length == 1) {
        formData.append("type", 11);
      }

      formData.append("dateID", localStorage.getItem("dateID"));
      formData.append("remark", "กำหนดรับซื้อแบบตัวรายการ");
      dispatch(createPriceLimit({ data: formData }))
        .unwrap()
        .then(() => {
          alert.success("เพิ่มข้อมูลสำเร็จ");
          clearForm();
          dispatch(getPriceLimit({ search: "" }));
          setShowForm(false);
        })
        .catch((error) => console.log(error));
    }
  };

  const clearForm = () => {
    setDefaultValue([]);
    setValue("id", "");
    setValue("number", null);
    setValue("price1", null);
    setValue("price2", null);
    setValue("price3", null);
    setValue("return1", null);
  };
  return (
    <form onSubmit={handleSubmit(submit)}>
      <input {...register("id")} className="form-control" type={"hidden"} />

      <div className="row">
        <div className="col-md-3 col-12 mb-2">
          <div>
            <label htmlFor="">หมายเลข</label>
          </div>
          <input
            {...register("number", { required: true })}
            className="form-control"
            placeholder="หมายเลข"
            type={"text"}
            maxLength={3}
          />
          {errors.number && (
            <span className="text-danger">หมายเลข ต้องไม่ว่างเปล่า</span>
          )}
        </div>
        <div className="col-md-2 col-12 mb-2">
          <div>
            <label htmlFor="">2/3ตัวบน</label>
          </div>
          <input
            {...register("price1", { required: false })}
            className="form-control"
            placeholder="บน"
            type={"number"}
            min={0}
          />
        </div>
        <div className="col-md-3 col-12 mb-2">
          <div>
            <label htmlFor="">3ตัวโต๊ด</label>
          </div>
          <input
            {...register("price3", { required: false })}
            className="form-control"
            placeholder="โต๊ด"
            type={"number"}
            min={0}
          />
        </div>
        <div className="col-md-2 col-12 mb-2">
          <div>
            <label htmlFor="">2/3ตัวล่าง</label>
          </div>
          <input
            {...register("price2", { required: false })}
            className="form-control"
            placeholder="ล่าง"
            type={"number"}
            min={0}
          />
        </div>
        <div className="col-md-2 col-12 mb-2">
          <div>
            <label htmlFor="">ส่งเจ้าก่อน</label>
          </div>
          <input
            {...register("return1", { required: false })}
            className="form-control"
            placeholder="ส่งเจ้าก่อน"
            type={"number"}
            min={0}
          />
        </div>
        <div className="col-md-2 col-12 mb-2">
          <button
            type="button"
            className="btn btn-secondary rounded"
            onClick={() => setShowForm(false)}
          >
            <i className="fa fa-angle-double-left"></i> ยกเลิก
          </button>{" "}
          <button type="submit" className="btn btn-success rounded">
            บันทึก
          </button>
        </div>
      </div>
    </form>
  );
};

export default PriceLimitForm;
