import React from "react";
import Layout from "../../components/v2/Layout";

const NumberLimit = () => {
  return (
    <Layout>
      <h4>กำหนดเลขอั้น เลขไม่รับ</h4>
    </Layout>
  );
};

export default NumberLimit;
