import React, { useEffect, useState } from "react";
import Layout from "../../components/v2/Layout";
import { getReport1 } from "../../redux/report1Slice";
import { useDispatch, useSelector } from "react-redux";
import ReportList from "../../components/v2/ReportList";

const Report1 = () => {
  const { results } = useSelector((state) => state.report1);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(getReport1())
      .unwrap()
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <Layout>
      <h3>รายงานส่งเจ้ามือ</h3>
      {loading && (
        <h1 className="text-center">
          <i className="fa fa-motorcycle fa-spin fa-fw"></i>
        </h1>
      )}
      <div className="mb-3">
        {results &&
          results.data &&
          results.data.output3 &&
          results.data.output3.length > 0 && (
            <div className="card-body text-white">
              <div>รวมทั้งหมด</div>
              <div>บน {results.totalAll.total1} บาท</div>
              <div>ล่าง {results.totalAll.total2} บาท</div>
              <div>โต๊ด {results.totalAll.total3} บาท</div>
              <div>
                รวมทั้งหมด{" "}
                <b>
                  <u>{results.totalAll.total4}</u>
                </b>{" "}
                บาท
              </div>
            </div>
          )}
      </div>
      {results &&
        results.data &&
        results.data.output2 &&
        results.data.output2.length > 0 && (
          <ReportList
            title="เลข 2 ตัว"
            results={results.data.output2}
            total1={results.total2.total1}
            total2={results.total2.total2}
            id="exportNumber2"
            name={localStorage.getItem("name")}
          />
        )}

      {results &&
        results.data &&
        results.data.output1 &&
        results.data.output1.length > 0 && (
          <ReportList
            title="เลขวิ่ง"
            results={results.data.output1}
            total1={results.total1.total1}
            total2={results.total1.total2}
            id="exportNumber1"
          />
        )}

      {results &&
        results.data &&
        results.data.output3 &&
        results.data.output3.length > 0 && (
          <ReportList
            title="เลข 3 ตัว"
            results={results.data.output3}
            total1={results.total3.total1}
            total2={results.total3.total2}
            total3={results.total3.total3}
            id="exportNumber3"
          />
        )}
    </Layout>
  );
};

export default Report1;
