import React, { useState } from "react";
import ListDetailNumber from "./ListNumberDetail";

import { useDispatch, useSelector } from "react-redux";
import { getNumberById } from "../../redux/numberSlice";
import { useNavigate } from "react-router-dom";
import _debounce from "lodash/debounce";
import Search from "./Search";

const ListNumber = ({ numbers, handleSearch }) => {
  const { numberDetail, loading, error, statusChange } = useSelector(
    (state) => state.numbers
  );
  const [preview, setPreview] = useState(false);
  const [selectData, setSelectData] = useState("");
  // const [value, setValue] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const findByID = (data) => {
    setSelectData(data);
    dispatch(
      getNumberById({
        cusID: data.cusID,
        billName: data.billName,
        dateID: data.dateID,
      })
    );
    setPreview(true);
  };

  //search
  const search = (value) => {
    handleSearch(value);
  };

  const edit = (data) => {
    //cusID, billName
    navigate(`/sell/${data.cusID}-${data.billName}-${data.dateID}`);
    // alert(data.billName);
  };

  return (
    <div className="card-body">
      {preview ? (
        <>
          {numberDetail && numberDetail.data && numberDetail.data.length > 0 ? (
            <div className="mb-3 mt-3">
              <ListDetailNumber
                selectData={selectData}
                numberDetail={numberDetail}
                setPreview={setPreview}
              />
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <>
          <div className="mb-3">
            <Search placeholder={"ค้นหา"} handleSearch={search} />
          </div>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-center" style={{ width: "100px" }}></th>
                  <th className="text-center">ลูกค้า</th>
                  <th className="text-center">บิล</th>
                  <th className="text-center">ตรง</th>
                  <th className="text-center">โต๊ด</th>
                  <th className="text-center">ล่าง</th>
                </tr>
              </thead>
              <tbody>
                {numbers &&
                  numbers.data &&
                  numbers.data.map((n, i) => (
                    <tr key={i}>
                      <td className="text-center">
                        <button
                          onClick={() => {
                            edit(n);
                          }}
                          className="text-white btn btn-sm btn-primary rounded"
                        >
                          <i className="fa fa-edit"></i> แก้ไข
                        </button>{" "}
                        <button
                          onClick={() => {
                            findByID(n);
                          }}
                          className="text-white btn btn-secondary btn-sm rounded"
                        >
                          <i className="fa fa-eye"></i> แสดง
                        </button>
                      </td>
                      <td>{n.cusName}</td>
                      <td className="text-right">{n.billName}</td>
                      <td className="text-right">{n.price1}</td>
                      <td className="text-right">{n.price3}</td>
                      <td className="text-right">{n.price2}</td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td className="text-right">รวม</td>
                  <td className="text-right">
                    {numbers && numbers.total && numbers.total.total1}
                  </td>
                  <td className="text-right">
                    {numbers && numbers.total && numbers.total.total3}
                  </td>
                  <td className="text-right">
                    {numbers && numbers.total && numbers.total.total2}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default ListNumber;
