import React from "react";

const TextInput = ({
  register,
  name,
  label,
  errors,
  required,
  placeholder,
  info,
  infotext,
  type,
  hiddenLabels,
  min,
  max,
  maxLength,
  readonly,
  onChange,
  onFocus,
}) => {
  return (
    <div className="form-group row">
      {required == "required" ? (
        <React.Fragment>
          {!hiddenLabels ? (
            <label htmlFor={label} className="col-12">
              {label} <span className="text-danger">*</span>
            </label>
          ) : (
            <React.Fragment></React.Fragment>
          )}

          <div className="">
            <input
              id={name}
              className="form-control"
              {...register(name, {
                required: "required",
                maxLength: maxLength,
                onChange: onChange,
              })}
              type={type}
              placeholder={placeholder}
              min={min}
              readOnly={readonly} 
            />
          </div>
          <div className="alert-assertive">
            {errors[name] && errors[name].type === "required" && (
              <p className="mb-0 alert alert-danger">กรุณากรอก {label}</p>
            )}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {!hiddenLabels ? (
            <label htmlFor={label} className="col-12">
              {label}
              {info == true ? (
                <React.Fragment>
                  <span className="price-sellwithus-info">
                    <i aria-hidden="true" className="fas fa-info"></i>
                  </span>
                </React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </label>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          <div className="">
            <input
              id={name}
              className="form-control"
              {...register(name, {
                onChange: onChange,
              })}
              type={type}
              placeholder={placeholder}
              min={min}
              readOnly={readonly}
              onFocus={onFocus}
              // autoComplete={false}
              maxLength={maxLength}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default TextInput;
