import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";

const ReportList = ({
  title,
  results,
  total1,
  total2,
  total3,
  id,
  name,
  date,
}) => {
  const exportExcel = () => {
    var table = document.getElementById(id);
    var rows = [];
    for (var i = 0, row; (row = table.rows[i]); i++) {
      /* add a new records in the array */
      let column1 = row.cells[0] ? row.cells[0].innerText.replace(",", "") : "";
      let x = column1.split("");
      if (x[0] == 0) {
        column1 = "เลข 0 นำหน้า " + column1;
      }
      let column2 = row.cells[1] ? row.cells[1].innerText.replace(",", "") : "";
      let column3 = row.cells[2] ? row.cells[2].innerText.replace(",", "") : "";
      let column4 = row.cells[3] ? row.cells[3].innerText.replace(",", "") : "";
      let column5 = "";
      if (row.cells[4]) {
        column5 = row.cells[4].innerText.replace(",", "");
      }
      rows.push([
        column1.toString(),
        column2.toString(),
        column3.toString(),
        column4.toString(),
        column5.toString(),
      ]);
    }
    let csvContent = "data:text/csv;charset=utf-8,";
    rows.forEach(function (rowArray) {
      row = rowArray.join(",");
      csvContent += row.toString() + "\r\n";
    });
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", title + ".csv");
    document.body.appendChild(link);
    link.click();
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <div className="table-responsive mt-3">
        <div className="mb-3 text-right">
          {" "}
          <button className="btn btn-sm btn-info" onClick={() => exportExcel()}>
            ส่งออก {title}
          </button>{" "}
          <button className="btn btn-sm btn-info" onClick={handlePrint}>
            ส่งออก pdf
          </button>
        </div>
        <table className="table table-bordered" id={id} ref={componentRef}>
          <thead>
            <tr>
              <th>
                {name} {date} {title}
              </th>
              <th> </th>
              <th></th>
              {total3 && <th></th>}
            </tr>
            <tr>
              <th className="text-center">หมายเลข</th>
              <th className="text-center">บน</th>
              {total3 && <th className="text-center">โต๊ด</th>}
              <th className="text-center">ล่าง</th>
            </tr>
            <tr>
              <th className="text-right">รวม</th>
              <th className="text-right">{total1 || ""}</th>
              {total3 && <th className="text-right">{total3 || ""}</th>}
              <th className="text-right">{total2 || ""}</th>
            </tr>
          </thead>
          <tbody>
            {results.map((res, index) => (
              <tr key={index}>
                <td className="text-right">{res.number}</td>
                <td className="text-right">
                  {res.price1 && res.price1 == 0 ? "" : res.price1}
                </td>
                {total3 ? (
                  <>
                    <td className="text-right">
                      {res.price3 && res.price3 == 0
                        ? ""
                        : res.price3 != 0
                        ? res.price3
                        : ""}
                    </td>
                    <td className="text-right">
                      {res.price2 && res.price2 == 0 ? "" : res.price2}
                    </td>
                  </>
                ) : (
                  <td className="text-right">
                    {res.price2 && res.price2 == 0 ? "" : res.price2}
                  </td>
                )}
              </tr>
            ))}
          </tbody>

          <tfoot>
            <tr>
              <td className="text-right">รวม</td>
              <td className="text-right">{total1 || ""}</td>
              {total3 && <td className="text-right">{total3 || ""}</td>}
              <td className="text-right">{total2 || ""}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
};

export default ReportList;
