import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Config from "../components/Config";
import Layout from "../components/v2/Layout";
import { BASE_URL } from "../config";
import { useNavigate } from "react-router-dom";
const Setting = () => {
  let params = useParams();
  const [token, setToken] = useState("");
  const [datas, setDatas] = useState([]);
  let navigate = useNavigate();
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      setToken(token);
    } else {
      navigate("/login");
    }
  });

  return (
    <Layout>
      <div className="row mt-3">
        <div className="col-12">
          <ul className="nav nav-pills nav-fill navbar-setting">
            <li className="nav-item">
              <a
                className="nav-link active"
                aria-current="page"
                href="#"
                data-bs-target="#tab1"
                data-bs-toggle="tab"
              >
                2 ตัวบน
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#"
                data-bs-target="#tab2"
                data-bs-toggle="tab"
              >
                2 ตัวล่าง
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#"
                data-bs-target="#tab2"
                data-bs-toggle="tab"
              >
                3 ตัวตรง
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#"
                data-bs-target="#tab2"
                data-bs-toggle="tab"
              >
                3 ตัวโต้ด
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#"
                data-bs-target="#tab2"
                data-bs-toggle="tab"
              >
                3 ตัวล่าง
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#"
                data-bs-target="#tab2"
                data-bs-toggle="tab"
              >
                เลขวิ่ง
              </a>
            </li>
          </ul>
        </div>
        <div className="col-12 mt-3">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="tab1"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <h5 className="color-white">
                <Link to="/home">
                  <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                </Link>{" "}
                ตั้งค่าเลข 2 ตัวบน
              </h5>
              <Config type={1} />
            </div>
            <div
              className="tab-pane fade"
              id="tab2"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <h5 className="color-white">
                <Link to="/home">
                  <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                </Link>{" "}
                ตั้งค่าเลข 2 ตัวล่าง
              </h5>
              <Config type={2} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Setting;
