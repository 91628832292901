import React from "react";

const PreviewSell = ({ datas }) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="text-white fw-bold mb-2 text-center">แสดงตัวอย่างก่อนส่งโพย</div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="text-center">หมายเลข</th>
              <th className="text-center">บน</th>
              <th className="text-center">ล่าง</th>
              <th className="text-center">โต้ด</th>
            </tr>
          </thead>
          <tbody>
            {datas.data.map((data, index) => (
              <tr key={index}>
                <td className="text-center">{data.number}</td>
                <td className="text-end">{data.numberOn}</td>
                <td className="text-end">{data.numberLover}</td>
                <td className="text-end">{data.numberLover1}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td className="text-end">รวม</td>
              <td className="text-end">{datas.total.totalOn}</td>
              <td className="text-end">{datas.total.totalLover}</td>
              <td className="text-end">{datas.total.totalLover1}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default PreviewSell;
