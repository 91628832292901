import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../config";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import Menu from "../components/v2/Menu";

import { useDispatch, useSelector } from "react-redux";
import { sumPrice } from "../redux/numberSlice";
import moment from "moment";
const Home = () => {
  let navigate = useNavigate();
  const { numbers } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [createDate, setCreateDate] = useState("");
  //init data
  useEffect(() => {
    let token = localStorage.getItem("token");
    let dd = moment.locale("th");

    if (!token) {
      navigate("/login");
    }
    let date = localStorage.getItem("createDate");
    if (date) {
      setCreateDate(moment(date).format("DD/MM/YYYY"));
    }
    dispatch(sumPrice());
  }, []);
  console.log(numbers);
  return (
    <div className="container mt-3">
      <h4 className="tx-header text-center">
        เมนูหลัก <small className="">งวดวันที่: {createDate}</small>
      </h4>
      <div className="row g-3">
        <div className="col-4">
          <Menu
            title={"คีย์หวย"}
            link="/sell"
            icon={"/sell.png"}
            className='bg-menu'
            // style={{ background: "#49a8bb" }}
          />
        </div>

        <div
          className="col-8 text-center rounded-3"
          style={{ paddingLeft: "0px" }}
        >
          <div className="card-flex">
            <h2>{(numbers && numbers.total) || 0}</h2>
            <div>ยอดรวม</div>
          </div>
        </div>
      </div>

      <div className="row g-3">
        <div className="col-4">
          <Menu
            title={"รายการขาย"}
            link="/bill"
            icon={"/bill.png"}
            className='bg-menu'
            // style={{ background: "#49a8bb" }}
          />
        </div>
        <div className="col-4" style={{ paddingLeft: "0px" }}>
          <Menu
            title={"วงเงินรับ"}
            link="/price-limit"
            icon={"/money.png"}
            className='bg-menu'
            // style={{ background: "#49a8bb" }}
          />
        </div>
        <div className="col-4" style={{ paddingLeft: "0px" }}>
          {" "}
          <Menu
            title={"ข้อมูลสายงาน"}
            link="/customer"
            icon={"/customer.png"}
            className='bg-menu'
            // style={{ background: "#49a8bb" }}
          />
        </div>
      </div>

      <div className="row g-3">
        <div className="col-4">
          <Menu
            title={"รายงานสรุป "}
            link="/report-customer"
            icon={"/report1.png"}
            className='bg-menu'
            // style={{ background: "#49a8bb" }}
          />
        </div>
        <div className="col-4" style={{ paddingLeft: "0px" }}>
          <Menu
            title={"ส่งเจ้ามือ"}
            link="/report2"
            icon={"/report2.png"}
            className='bg-menu'
            // style={{ background: "#49a8bb" }}
          />
        </div>
        <div className="col-4" style={{ paddingLeft: "0px" }}>
          <Menu
            title={"ตรวจหวย"}
            link="/result"
            icon={"/report3.png"}
            className='bg-menu'
            // style={{ background: "#49a8bb" }}
          />
        </div>
      </div>
      <div className="mt-2">
        <Menu
          title={"ออกจากระบบ"}
          link="/logout"
          style={{ background: "rgb(244 67 54 / 49%)" }}
        />
      </div>
      <br />
    </div>
  );
};

export default Home;
