import React, { useEffect, useState } from "react";
import Layout from "../../components/v2/Layout";
import { getPriceLimit, deletePriceLimit } from "../../redux/priceLimitSlice";
import { useDispatch, useSelector } from "react-redux";
import PriceLimitForm from "../../components/v2/PriceLimitForm";
import { useAlert } from "react-alert";
const PriceLimit = () => {
  const alert = useAlert();
  const { results, error, loading } = useSelector((state) => state.priceLimit);
  const [defaultValue, setDefaultValue] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPriceLimit({ search: "" }));
  }, []);

  const remove = ({ id }) => {
    dispatch(deletePriceLimit({ id }))
      .unwrap()
      .then(() => {
        alert.success("ลบข้อมูลสำเร็จ");
        dispatch(getPriceLimit({ search: "" }));
      })
      .catch((error) => alert.error(error));
  };

  return (
    <Layout>
      <h3>กำหนดวงเงินรับ</h3>
      <div className=" mb-3">
        {showForm === true && (
          <PriceLimitForm
            defaultValue={defaultValue}
            setShowForm={setShowForm}
            setDefaultValue={setDefaultValue}
          />
        )}
      </div>
      {showForm === false && (
        <div className="card-body">
          <div className="text-right mb-3">
            <button
              className="btn btn-sm btn-success rounded shadow"
              onClick={() => setShowForm(true)}
            >
              <i className="fa fa-plus"></i> เพิ่มรายการ
            </button>
          </div>
          <div className="table-responsive">
            <table className="table  table-bordered">
              <thead>
                <tr>
                  <th style={{ width: "100px" }}></th>
                  <th className="text-center fs-5">หมายเลข</th>
                  <th className="text-center fs-5">บน</th>
                  <th className="text-center fs-5">โต๊ด</th>
                  <th className="text-center fs-5">ล่าง</th>
                  <th className="text-center fs-5">ส่งเจ้าก่อน</th>
                </tr>
              </thead>
              <tbody>
                {loading == true ? (
                  <tr>
                    <td colSpan={5}>Loading...</td>
                  </tr>
                ) : (
                  <>
                    {error != null ? (
                      <tr>
                        <td colSpan={5}>{error}</td>
                      </tr>
                    ) : (
                      <>
                        {results &&
                          results.length > 0 &&
                          results.map((res, index) => (
                            <tr key={index}>
                              <td className="text-center p-2">
                                <button
                                  className="btn btn-sm btn-primary rounded-1"
                                  onClick={() => {
                                    setDefaultValue(res);
                                    setShowForm(true);
                                  }}
                                >
                                  <i className="fa fa-edit"></i> แก้ไข
                                </button>{" "}
                                {res.type != 1 &&
                                res.type != 2 &&
                                res.type != 3 ? (
                                  <button
                                    className="btn btn-sm btn-primary"
                                    onClick={() => remove({ id: res.id })}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td className="text-center p-2 fs-5">{res.number}</td>
                              <td className="text-center p-2 fs-5">{res.price1}</td>
                              <td className="text-center p-2 fs-5">{res.price3}</td>
                              <td className="text-center p-2 fs-5">{res.price2}</td>
                              <td className="text-center p-2 fs-5">{res.return1}</td>
                            </tr>
                          ))}
                      </>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default PriceLimit;
