import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Layout from "../../components/v2/Layout";

import { useDispatch, useSelector } from "react-redux";
import { createNumbers, getNumbers } from "../../redux/numberSlice";
import { useAlert } from "react-alert";
import { getCustomers } from "../../redux/customerSlice";
import { useParams } from "react-router-dom";
import PreviewSell from "../../components/v2/PreviewSell";
import InforSell from "../../components/v2/InforSell";
import { getNumberById } from "../../redux/numberSlice";
import ModalSell from "../../components/v2/ModalSell";

const Sell = () => {
  const [numberStr, setNumberStr] = useState("");
  const [customNumber, setCustomNumber] = useState("");
  const [datas, setDatas] = useState("");
  const alert = useAlert();
  let params = useParams();
  const { customers } = useSelector((state) => state.customers);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  //getData
  const getData = (data) => {
    let output = [];
    let list = data.replace(/\r\n/g, "\n").split(String.fromCharCode(10));

    if (list.length > 0) {
      for (let a of list) {
        if (a.includes("=")) {
          let arr1 = a.split("=");
          if (arr1.length == 2) {
            //ต้องมี 2 digit
            let arr2 = arr1[0].split(" ");

            for (let i of arr2) {
              if (i !== "") {
                let number = i; //ตัวเลข
                let numberTypeArr = arr1[1].split("*"); //บน ล่าง
                let numberOn = numberTypeArr[0]; //บน
                let numberLover = numberTypeArr[1]; // ล่าง
                let numberLover1 = 0;
                let type = "";
                if (number.length === 2) {
                  //2 ตัวบนล่าง
                  type = 2;
                } else if (number.length === 3) {
                  //3ตัว ตรง โต้ด
                  numberLover = numberTypeArr[2]; // โต้ด
                  numberLover1 = numberTypeArr[1]; // ล่าง
                  type = 3;
                } else if (number.length === 1) {
                  //1 ตัว วิ่ง
                  type = 1;
                }
                output.push({
                  number: number, //เลข
                  numberOn: numberOn ? numberOn : 0, //บน
                  numberLover: numberLover ? numberLover : 0, //2 ตัวล่าง 3 ตัวโต้ด
                  numberLover1: numberLover1 ? numberLover1 : 0, //3ตัวล่าง
                  type: type, //1=เลขวิ่ง,2=2ตัว,3=3ตัว
                });
              }
            } //end for
          }
        } else {
          for (let i of a) {
            if (i !== "") {
              let numberArr = i.split("=");
              if (numberArr.length > 1) {
                let number = numberArr[0]; //เลข
                let numberTypeArr = numberArr[1].split("*"); //บน ล่าง
                let numberOn = numberTypeArr[0]; //บน
                let numberLover = numberTypeArr[1]; // ล่าง
                let numberLover1 = 0;
                let type = "";
                if (number.length === 2) {
                  //2 ตัวบนล่าง
                  type = 2;
                  //console.log(`เลข ${number} บน ${numberOn} ล่าง ${numberLover}`);
                } else if (number.length === 3) {
                  //3ตัว ตรง โต้ด
                  numberLover = numberTypeArr[2]; // โต้ด
                  numberLover1 = numberTypeArr[1]; // ล่าง
                  type = 3;
                  // console.log(
                  //   `เลข ${number} บน ${numberOn} โต้ด ${numberLover} ล่าง ${numberLover1} `
                  // );
                } else if (number.length === 1) {
                  //1 ตัว วิ่ง
                  type = 1;
                  //console.log(`เลข ${number} บน ${numberOn} ล่าง ${numberLover}`);
                }
                output.push({
                  number: number, //เลข
                  numberOn: numberOn ? numberOn : 0, //บน
                  numberLover: numberLover ? numberLover : 0, //2 ตัวล่าง 3 ตัวโต้ด
                  numberLover1: numberLover1 ? numberLover1 : 0, //3ตัวล่าง
                  type: type, //1=เลขวิ่ง,2=2ตัว,3=3ตัว
                });
              }
            }
          }
        }
      }
    }
    let total = { totalOn: 0, totalLover: 0, totalLover1: 0 };
    for (let i of output) {
      total.totalOn += i.numberOn ? parseInt(i.numberOn) : 0;
      total.totalLover += i.numberLover ? parseInt(i.numberLover) : 0;
      total.totalLover1 += i.numberLover1 ? parseInt(i.numberLover1) : 0;
    }
    return { total: total, data: output };
  };

  //submit number
  const onSubmit = (data) => {
    // return false;
    if (!datas) {
      alert.error("กรุณาแก้ไขหมายเลข");
      return false;
    }

    let output = [];
    for (let i of datas["data"]) {
      output.push({
        number: i.number,
        price1: i.numberOn,
        price2: i.numberLover, //ล่าง
        price3: i.numberLover1, //โต๊ด
        type: i.type,
        dateID: localStorage.getItem("dateID") || "",
        cusID: data["cusID"],
        billName: data["billName"],
      });
    }

    let formData = new FormData();
    formData.append("id", data.id || "");
    formData.append("data", JSON.stringify(output));
    dispatch(createNumbers(formData))
      .unwrap()
      .then((res) => {
        console.log(res);
        alert.success("เพิ่มข้อมูลสำเร็จ");
        dispatch(getNumbers());
        clearForm();
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  const clearForm = () => {
    setValue("billName", "001");
    setValue("list", "");
    setDatas("");
  };

  //init customer
  useEffect(() => {
    dispatch(getCustomers({ search: "" }))
      .unwrap()
      .then(() => {
        // setValue("cusID", "1");
      });
    setValue("billName", "002");
    // setValue("list", "10=5*5");
    setValue("id", params.id);
  }, []);

  //get data by id
  useEffect(() => {
    if (params.id) {
      setValue("id", params.id);
      let data = params.id.split("-");
      dispatch(
        getNumberById({
          cusID: data[0],
          billName: data[1],
          dateID: data[2],
        })
      )
        .unwrap()
        .then((res) => {
          let result = "";
          for (let i of res.data) {
            if (i.type > 2) {
              result += `${i.number}=${i.price1}*${i.price3}*${i.price2}\n`;
            } else {
              result += `${i.number}=${i.price1}*${i.price2}\n`;
            }
          }

          setTimeout(() => {
            setValue("cusID", data[0]);
          }, 500);
          setValue("billName", data[1]);
          setValue("list", result);
        })
        .catch((error) => console.log(error));
    }
  }, [params.id]);

  useEffect(() => {
    if (customNumber) {
      let defaultValue = "";
      if (numberStr) {
        defaultValue = `${numberStr}\n${customNumber}`;
      } else {
        defaultValue = `${customNumber}`;
      }
      setNumberStr(defaultValue);
      setValue("list", defaultValue);
      let d = getData(defaultValue);
      setDatas(d);
    }
  }, [customNumber]);

  //console.log(customers);
  return (
    <Layout>
      <h3>คีย์หวย</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <input
            id="id"
            {...register("id", { required: false })}
            className="form-control"
            type="hidden"
          />
        </div>
        <div className="row">
          <div className="col-5">
            <div className="mt-2">
              <div>
                <label htmlFor="cusID">ชื่อบิล</label>
              </div>
              <input
                id="billName"
                {...register("billName", { required: true })}
                className="form-control"
                readOnly={params.id ? true : false}
              />
              {errors.billName && (
                <span className="text-danger">กรุณากรอกชื่อบิล</span>
              )}
            </div>
          </div>
          <div className="col-7">
            {" "}
            <div className="mt-2">
              <div>
                <label htmlFor="cusID">ชื่อลูกค้า</label>
              </div>
              <select
                id="cusID"
                {...register("cusID", { required: true })}
                className="form-select"
              >
                <option value={""}>เลือกลูกค้า</option>
                {customers.map((c, index) => (
                  <option key={index} value={c.id}>
                    {c.cusName}
                  </option>
                ))}
              </select>
              {errors.cusID && (
                <span className="text-danger">กรุณาเลือกลูกค้า</span>
              )}
            </div>
          </div>
        </div>

        <ModalSell setCustomNumber={setCustomNumber} />
        <div className="mt-3">
          <div>
            <label htmlFor="list">
              หมายเลข{" "}
              <label
                className="btn btn-link"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <i className="fa fa-hand-o-right" aria-hidden="true"></i>{" "}
                กรอกเลขกลับ
              </label>
            </label>
          </div>
          <textarea
            className="form-control"
            rows={7}
            id="list"
            {...register("list", { required: true })}
            onChange={(e) => {
              setNumberStr(e.target.value);
              // console.log(e.target.value);
              let data = getData(e.target.value);
              setDatas(data);
            }}
          />
          {errors.list && <span className="text-danger">กรุณาหมายเลข</span>}
        </div>

        <InforSell />

        <div className="mt-3 mb-5 d-grid gap-2">
          <input
            type="submit"
            value={"ส่งโพย"}
            className="btn btn-lg btn-primary rounded-2 shadow"
          />
        </div>
      </form>
      {datas !== "" && <PreviewSell datas={datas} />}
    </Layout>
  );
};

export default Sell;
