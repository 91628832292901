import React, { useCallback } from "react";
import _debounce from "lodash/debounce";

const Search = ({ handleSearch, placeholder }) => {
  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);
  //search
  function handleDebounceFn(inputValue) {
    handleSearch(inputValue);
  }
  return (
    <input
      type="text"
      placeholder={placeholder}
      onChange={(e) => {
        debounceFn(e.target.value);
      }}
    />
  );
};

export default Search;
