import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import numberService from "../services/number";

export const getReport1 = createAsyncThunk("report1/getReport1", async () => {
  try {
    const dateID = localStorage.getItem("dateID");
    let res = await numberService.findByDate({ dateID });
    if (res.data.status == "ok") {
      return res.data.data;
    } else {
      console.log(res);
      return [];
    }
  } catch (error) {
    return error;
  }
});

const report1Slice = createSlice({
  name: "report1",
  initialState: {
    results: [],
    loading: false,
    error: null,
  },
  extraReducers: {
    //loading
    [getReport1.pending]: (state) => {
      state.loading = true;
    },
    //success
    [getReport1.fulfilled]: (state, action) => {
      state.loading = false;
      state.results = action.payload;
    },
    //error
    [getReport1.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export default report1Slice.reducer;
