import React from "react";

const ListDetailNumber = ({
  selectData,
  numberDetail,
  setPreview,
  edit,
  setSelectID,
}) => {
  return (
    <div>
      <label onClick={() => setPreview(false)}>
        <i className="fa fa-chevron-circle-left" aria-hidden="true"></i>
        &nbsp;ย้อนกลับ
      </label>
      <h5 className={"mt-3 mb-3"}>
        ชื่อลูกค้า <u className="text-yellow">{selectData.cusName}</u> บิลที่{" "}
        <u className="text-yellow">{selectData.billName}</u>{" "}
        {edit && (
          <button
            onClick={() => {
              setSelectID(
                `${selectData.cusID}-${selectData.billName}-${selectData.dateID}`
              );
              setPreview(false);
            }}
            className="btn btn-sm btn-primary"
          >
            <i className="fa fa-edit"></i> แก้ไข
          </button>
        )}
      </h5>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className={"text-center"}>หมายเลข</th>
              <th className={"text-center"}>บน</th>
              <th className={"text-center"}>โต๊ด</th>
              <th className={"text-center"}>ล่าง</th>
            </tr>
          </thead>
          <tbody>
            {numberDetail.data.map((n) => (
              <tr key={n.id}>
                <td className="pl-2">{n.number}</td>
                <td className="text-right">{n.price1 == 0 ? "" : n.price1}</td>
                <td className="text-right">{n.price3 == 0 ? "" : n.price3}</td>
                <td className="text-right">{n.price2 == 0 ? "" : n.price2}</td>
              </tr>
            ))}
          </tbody>
          {numberDetail.total && (
            <tfoot>
              <tr>
                <td>รวม</td>
                <td className="text-right">{numberDetail.total.total1}</td>
                <td className="text-right">{numberDetail.total.total3}</td>
                <td className="text-right">{numberDetail.total.total2}</td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    </div>
  );
};
export default ListDetailNumber;
