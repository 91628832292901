import React, { useEffect } from "react";
import axios from "axios";

import { useForm } from "react-hook-form";
import { Base64, BASE_URL } from "../config";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
const Login = () => {
  let navigate = useNavigate();
  const alert = useAlert();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    const user = Base64.btoa(data.username + ":" + data.password);
    var config = {
      method: "post",
      url: `${BASE_URL}/user/login`,
      headers: {
        appname: "lottery",
        version: "1.0",
        Authorization: "Basic " + user,
      },
    };
    axios(config)
      .then(function (response) {
        if (response.data.status == "ok") {
          let { name } = response.data.data;
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("name", name);

          window.location.reload();
        } else {
          alert.error("กรุณาตรวจสอบ username หรือ password");
        }
      })
      .catch(function (error) {
        alert.error("กรุณาตรวจสอบ username หรือ password");
      });
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      navigate("/create-date");
    }
  }, []);
  return (
    <div className="flex-container ">
      <h1>
        <div
          style={{
            width: "100px",
            height: "100px",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            border: "3px solid rgb(73, 168, 187)",
            borderRadius: "100%",
          }}
        >
          L
        </div>
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label htmlFor="username">Username:</label>
          <input
            {...register("username", { required: true })}
            className="form-control"
            placeholder="ชื่อผู้ใช้งาน"
            style={{ fontSize: "20px" }}
          />
          {errors.username && (
            <span className="text-danger">Username ต้องไม่ว่างเปล่า</span>
          )}
        </div>
        <div className="form-group mt-2 ">
          <label htmlFor="password">Password:</label>
          <input
            {...register("password", { required: true })}
            className="form-control"
            placeholder="รหัสผ่าน"
            style={{ fontSize: "20px" }}
            type={"password"}
          />
          {errors.password && (
            <span className="text-danger">Password ต้องไม่ว่างเปล่า</span>
          )}
        </div>

        <div className="d-grid gap-2 mt-4">
          <button type="submit" className="btn btn-primary rounded">
            เข้าสู่ระบบ
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
