import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Layout from "../components/Layout";
import Number from "../components/Number";

const NumbersLottery = () => {
  return (
    <Layout>
      <div className="">
        <div className="row mt-5">
          <div className="col-12">
            <ul className="nav nav-pills nav-fill">
              <li className="nav-item color-white">
                <a
                  className="nav-link active"
                  aria-current="page"
                  href="#"
                  data-bs-target="#tab1"
                  data-bs-toggle="tab"
                >
                  2 ตัวบน
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#"
                  data-bs-target="#tab2"
                  data-bs-toggle="tab"
                >
                  2 ตัวล่าง
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 ">
            <div className="tab-content mt-3" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="tab1"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <h3 className="mb-3 color-white">
                  <Link to="/home ">
                    <i
                      className="fa fa-angle-double-left"
                      aria-hidden="true"
                    ></i>
                  </Link>{" "}
                  หวย 2 ตัวบน
                </h3>
                <div className="mb-3 color-white">
                  *คลิกที่ตัวเลขด้านล่างเพื่อป้อนราคา
                </div>
                <Number type={1} />
              </div>
              <div
                className="tab-pane fade"
                id="tab2"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <h3 className="mb-3 color-white">
                  <Link to="/home">
                    <i
                      className="fa fa-angle-double-left"
                      aria-hidden="true"
                    ></i>
                  </Link>{" "}
                  หวย 2 ตัวล่าง
                </h3>
                <div className="mb-3 color-white">
                  *คลิกที่ตัวเลขด้านล่างเพื่อป้อนราคา
                </div>
                <Number type={2} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NumbersLottery;
