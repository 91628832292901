import axios from "axios";
import React, { useEffect } from "react";

const Test = () => {
  const updateData = () => {
    /*
    setInterval(function(){
        for (let i = 0; i <= 1000; i++) {
      var form = new FormData();
      form.append("setlimitpaynumber", "ok");
      form.append("txtnumbe", "22");
      form.append("Chconvert_num", "true");

      var settings = {
        url: "https://www.mungmee88.com/lotto.php",
        method: "POST",
        timeout: 0,
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
        data: form,
      };

      $.ajax(settings).done(function (response) {
        console.log(response);
      });
    }
    },5000)*/
  };

  useEffect(() => {
    updateData();
  });
  return <div></div>;
};

export default Test;
