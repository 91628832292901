import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BASE_URL } from "../config";
import { useAlert } from "react-alert";
const FormPrice = ({
  id,
  numberID,
  price,
  token,
  handleSubmitFormsuccess,
  setPrice,
  setSelectID,
}) => {
  const alert = useAlert();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (id) {
      setValue("id", id);
    }
    if (numberID) {
      setValue("numberID", numberID);
    }
    if (price) {
      setValue("price", price);
    }
    // setValue("lover", 1);
  }, [id, numberID]);

  const onSubmit = (data) => {
    let method = `post`;
    let url = `${BASE_URL}/price-number`;

    var formData = new FormData();
    formData.append("numberID", data.numberID);
    formData.append("price", data.price);
    if (data.lover) {
      formData.append("lover", data.lover);
    }
    if (data.back1) {
      formData.append("back1", data.back1);
    }
    if (data.back2) {
      formData.append("back2", data.back2);
    }
    if (data.id != "") {
      url += `/${data.id}`;
      method = "put";
    }
    var config = {
      method: method,
      url: url,
      headers: {
        Authorization: "Bearer " + token,
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status == "ok") {
          alert.success(response.data.message);
          handleSubmitFormsuccess();
          if (data.id) {
            setValue("id", "");
          }
          setSelectID("");
          setPrice("");
          setValue("price", "");
        } else {
          alert.error(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-none">
          <input {...register("id")} className="form-control" />
          <input {...register("numberID")} className="form-control" />
        </div>

        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-4">
                <input
                  name="lover"
                  id="lover"
                  {...register("lover")}
                  type={"checkbox"}
                />{" "}
                <label htmlFor="lover">บน/ล่าง</label>
              </div>
              <div className="col-4">
                <input
                  name="back1"
                  id="back1"
                  {...register("back1")}
                  type={"checkbox"}
                  min={0}
                />{" "}
                <label htmlFor="back1">กลับบน</label>
              </div>
              <div className="col-4" style={{ whiteSpace: "nowrap" }}>
                <input
                  name="back2"
                  id="back2"
                  {...register("back2")}
                  type={"checkbox"}
                  min={0}
                />{" "}
                <label htmlFor="back2">กลับล่าง</label>
              </div>
            </div>
            <div className="form-group">
              <input
                {...register("price", { required: true })}
                className="form-control"
                placeholder="ใส่ราคาหวย"
                type={"number"}
                min={0}
              />
              {errors.price && <span>ราคา ต้องไม่ว่างเปล่า</span>}
            </div>
          </div>
          <div className="col-12 mt-2">
            {" "}
            <div className="d-grid gap-2">
              <button type="submit" className="btn btn-success">
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default FormPrice;
