import React, { useEffect, useState, useRef } from "react";
import httpCommon from "../../services/http-common";
import { currencyFormat } from "../../config";
import { useDownloadExcel } from "react-export-table-to-excel";

import XLSX from "sheetjs-style";

const Report2Digit = () => {
  const [data2, setData2] = useState([]);
  const [total2, setTotal2] = useState();
  const [digit2, setDigit2] = useState([]);

  const tableRef = useRef(null);
  const getReport = async () => {
    try {
      const dateId = localStorage.getItem("dateID");
      const result = (await httpCommon.post(`/report2?dateId=${dateId}`)).data;
      if (result.msg == "ok") {
        const d2 = result.data._2digit.data;
        const total = result.data._2digit.total;
        setData2(d2);
        setTotal2(total);

        let d2arr = [];
        d2arr.push(["ส่งออก 2 ตัว บนล่าง"]);
        d2arr.push(["หมายเลข", "บน", "ล่าง"]);
        d2arr.push([
          "ยอดรวม",
          currencyFormat({
            number: total["totalReturnAll1"],
            decimal: 0,
          }),
          currencyFormat({
            number: total["totalReturnAll2"],
            decimal: 0,
          }),
        ]);
        for (let i of d2) {
          d2arr.push([i["number"], i["totalReturn1"], i["totalReturn2"]]);
        }
        setDigit2(d2arr);
        // console.log(d2arr);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const export2 = async () => {
    console.log(digit2);
    var workbook = XLSX.utils.book_new();
    var ws = XLSX.utils.aoa_to_sheet(digit2);
    const alignmentCenter = {
      horizontal: "center",
      vertical: "center",
      wrapText: true,
    };
    const ThinBorder = {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    };
    const LightBlue = {
      fgColor: { rgb: "FFFFFF" },
    };
    const fillAlignmentBorder = {
      fill: LightBlue,
      alignment: alignmentCenter,
      // border: ThinBorder,
      font: { bold: true },
    };
    ws["A1"].s = fillAlignmentBorder;
    ws["A2"].s = { alignment: alignmentCenter, font: { bold: true } };
    ws["B2"].s = { alignment: alignmentCenter, font: { bold: true } };
    ws["C2"].s = { alignment: alignmentCenter, font: { bold: true } };

    ws["A3"].s = { alignment: { horizontal: "right" }, font: { bold: true } };
    ws["B3"].s = { alignment: { horizontal: "right" }, font: { bold: true } };
    ws["C3"].s = { alignment: { horizontal: "right" }, font: { bold: true } };

    for (let i = 4; i <= digit2.length; i++) {
      ws[`B${i}`].s = { numFmt: '""#,##0_);\\(""#,##0\\)' };
      ws[`C${i}`].s = { numFmt: '""#,##0_);\\(""#,##0\\)' };
    }
    const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }];
    //ที่นี่ s = start, r = row, c=col, e= end
    ws["!merges"] = merge;

    XLSX.utils.book_append_sheet(workbook, ws, "2ตัวบนล่าง");
    XLSX.writeFile(workbook, "2ตัวบนล่าง.xlsx");
  };

  useEffect(() => {
    getReport();
  }, []);
  //   console.log(data2);
  return (
    <>
      <div className="card-body">
        <h1 className="fs-3 text-center mt-2">ส่งออกเลข 2 ตัว</h1>

        <div className="text-end">
          <button
            className="btn btn-sm btn-success rounded-1 mb-2"
            onClick={export2}
          >
            <i className="fa fa-file-excel-o"></i> ส่งออก เลข 2 ตัว
          </button>
        </div>
        <div className="table-responsive text-white">
          <table
            id="tb2digit"
            className="table table-hover table-bordered"
            ref={tableRef}
          >
            <thead>
              <tr>
                <th className="text-center" style={{ width: "200px" }}>
                  ส่งออกเลข 2 ตัว บนล่าง
                </th>
                <th></th>
                <th colSpan={2} className="text-center">
                  <h3 className="fs-3 d-grid">
                    <span className="badge bg-danger">ส่งออกเจ้ามือ</span>
                  </h3>
                </th>
                <th colSpan={2} className="text-center">
                  <h3 className="fs-3 d-grid">
                    <span className="badge bg-success">ยอดรับ</span>
                  </h3>
                </th>
              </tr>
              <tr>
                <th className="text-center">หมายเลข</th>
                <th className="text-center" style={{ width: "200px" }}>
                  ยอดแทง บน/ล่าง
                </th>
                <th className="text-center">บน</th>
                <th className="text-center">ล่าง</th>
                <th className="text-center">บน</th>
                <th className="text-center">ล่าง</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td className="text-end p-2 fw-bold p-2 fs-5">รวมทั้งหมด</td>

                <td className="text-center fw-bold p-2 fs-5" colSpan={2}>
                  {total2 &&
                    currencyFormat({
                      number: total2.returnAll,
                      decimal: 0,
                    })}
                </td>

                <td className="text-center fw-bold p-2 fs-5" colSpan={2}>
                  {total2 &&
                    currencyFormat({
                      number: total2.profitAll,
                      decimal: 0,
                    })}
                </td>
              </tr>

              <tr>
                <td className="text-end fw-bold p-2 fs-5">ยอดรวม</td>
                <td className="text-center fw-bold p-2 fs-6">
                  {total2 &&
                    currencyFormat({
                      number: total2.betAll,
                      decimal: 0,
                    })}
                </td>
                <td className="text-center fw-bold p-2 fs-6">
                  {total2 &&
                    currencyFormat({
                      number: total2.totalReturnAll1,
                      decimal: 0,
                    })}
                </td>
                <td className="text-center fw-bold p-2 fs-6">
                  {total2 &&
                    currencyFormat({
                      number: total2.totalReturnAll2,
                      decimal: 0,
                    })}
                </td>
                <td className="text-center fw-bold p-2 fs-6">
                  {total2 &&
                    currencyFormat({
                      number: total2.totalProfit1,
                      decimal: 0,
                    })}
                </td>
                <td className="text-center fw-bold p-2 fs-6">
                  {total2 &&
                    currencyFormat({
                      number: total2.totalProfit2,
                      decimal: 0,
                    })}
                </td>
              </tr>
              {data2.length > 0 &&
                data2.map((d, index) => (
                  <tr key={index}>
                    <td className="text-center">{d.number}</td>
                    <td className="text-center">
                      {d.totalPrice1} X {d.totalPrice2}
                    </td>
                    <td className="text-center">{d.totalReturn1}</td>
                    <td className="text-center">{d.totalReturn2}</td>
                    <td className="text-center">{d.total1}</td>
                    <td className="text-center">{d.total2}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Report2Digit;
