import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../config";
import { useParams } from "react-router-dom";
import TableComponent from "../components/TableComponent";
import "../custom.css";
const ViewReport = () => {
  const [token, setToken] = useState("");
  const [datas, setDatas] = useState([]);
  const [displayName, setDisplayName] = useState("");
  const [total, setTotal] = useState("");
  let params = useParams();

  const getDate = async () => {
    var config = {
      method: "get",
      url: `${BASE_URL}/report?dateID=${params.id}`,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === "ok") {
          setDatas(response.data.data);
          setTotal(response.data.total);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    let token = params.token;
    let name = params.name;
    if (token) {
      setToken(token);
    }
    if (name) {
      setDisplayName(name);
    }
  }, []);
  const decodeFunc = (encoded) => {
    let binary = atob(encoded);
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    return String.fromCharCode(...new Uint16Array(bytes.buffer));
  };
  useEffect(() => {
    if (token != "") {
      getDate();
    }
  }, [token]);
  return (
    <div className="container">
      <div className="row">
        <div style={{ textAlign: "right" }}>
          <button
            className="btnPrint btn btn-primary mt-3 mb-3"
            onClick={() => window.print()}
          >
            <i className="fa fa-print"></i> Print
          </button>
        </div>
        <div className="print-header color-white">
          วันที่: {atob(params.date)} ชื่อ: {decodeFunc(displayName)}
        </div>
        <div className="col-6" style={{ paddingRight: "0px" }}>
          {" "}
          <TableComponent
            label={"บน"}
            type={1}
            datas={datas}
            min={"66"}
            max={"99"}
            breakPage={false}
            total={total !== "" ? total.total1 : ""}
          />
        </div>
        <div className="col-6" style={{ paddingLeft: "0px" }}>
          <TableComponent
            label={"ล่าง"}
            type={2}
            datas={datas}
            min={"66"}
            max={"99"}
            breakPage={false}
            total={total != "" ? total.total2 : ""}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewReport;
