import React, { useEffect, useState } from "react";
import Layout from "../../components/v2/Layout";
import numberService from "../../services/number";
import { currencyFormat } from "../../config";

const Result = () => {
  const [number1, setNumber1] = useState(""); //2ตัวบน
  const [number2, setNumber2] = useState(""); //2ตัวล่าง
  const [number3, setNumber3] = useState(""); //3ตัวตรง
  const [number4, setNumber4] = useState(""); //3ตัวล่าง

  const [number5, setNumber5] = useState(""); //วิ่งบน
  const [number6, setNumber6] = useState(""); //วิ่งล่าง

  const [results, setResults] = useState([]);
  const [total, setTotal] = useState();
  const [process, setProcess] = useState(false);

  async function addResult() {
    if (number1 == 0 || number2 == 0 || number3 == 0) {
      return false;
    }
    try {
      const res = await numberService.addResult({
        number1,
        number2,
        number3,
        number4,
        number5,
        number6,
      });
      if (res.data.status == "ok") {
        alert("บันทึกผลรางวัลเรียบร้อย");
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function getResult() {
    try {
      let res = await numberService.getResult();
      if (res.data.status == "ok") {
        let { data } = res.data;
        if (data.number1) {
          setNumber1(data.number1);
        }
        if (data.number2) {
          setNumber2(data.number2);
        }
        if (data.number3) {
          setNumber3(data.number3);
        }
        if (data.number4) {
          setNumber4(data.number4);
        }
        if (data.number5) {
          setNumber5(data.number5);
        }
        if (data.number6) {
          setNumber6(data.number6);
        }
      }
    } catch (error) {
      console.log("Get Result Error ", error);
    }
  }

  async function getResultAll(ntype = "") {
    try {
      let r = await numberService.getResultAll(ntype);
      console.log(r);
      if (r.data.status == "ok") {
        setResults(r.data.data);
        setTotal(r.data.total);
      }
    } catch (error) {
      console.log("Get Result All Error ", error);
    }
  }
  async function processResult() {
    try {
      setProcess(true);
      setResults([]);
      let r = await numberService.processResult();
      if (r.data.status == "ok") {
        getResultAll();
      }
    } catch (error) {
      console.log("Process Result Error");
    }
  }
  useEffect(() => {
    getResult();
  }, []);

  function renderType(type) {
    let typeStr = "";
    if (type == "11") {
      typeStr = "วิ่งบน";
    } else if (type == "12") {
      typeStr = "วิ่งล่าง";
    } else if (type == "21") {
      typeStr = "2 ตัวบน";
    } else if (type == "22") {
      typeStr = "2 ตัวล่าง";
    } else if (type == "31") {
      typeStr = "3 ตัวตรง";
    } else if (type == "32") {
      typeStr = "3 ตัวโต๊ด";
    } else if (type == "33") {
      typeStr = "3 ตัวล่าง";
    }
    return typeStr;
  }
  async function search(v) {
    getResultAll(v);
  }
  return (
    <Layout>
      <h4 className="card-title">ตรวจหวย</h4>
      {process == false ? (
        <div className="card">
          <div className="card-body">
            <div className="row mt-2">
              <div className="col-6">
                <div>
                  <label>2 ตัวบน</label>
                  <input
                    value={number1}
                    onChange={(e) => setNumber1(e.target.value)}
                    type={"number"}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-6">
                <div>
                  <label>2 ล่าง</label>
                  <input
                    value={number2}
                    onChange={(e) => setNumber2(e.target.value)}
                    type={"number"}
                    className="form-control"
                  />
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-6">
                <label>3 ตัวตรง</label>
                <input
                  value={number3}
                  onChange={(e) => setNumber3(e.target.value)}
                  type={"number"}
                  className="form-control"
                />
              </div>
              <div className="col-6">
                <label>3 ตัวล่าง</label>
                <input
                  value={number4}
                  onChange={(e) => setNumber4(e.target.value)}
                  type={"number"}
                  className="form-control"
                />
              </div>
            </div>
            <div className="mt-3">
              <div className="row">
                <div className="col-6  d-grid gap-2">
                  <button className="btn btn-primary" onClick={addResult}>
                    บันทึกผล
                  </button>
                </div>
                <div className="col-6 d-grid gap-2">
                  <button
                    className="btn btn-outline-warning ms-3"
                    onClick={processResult}
                  >
                    คำนวณผล
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="row">
            <div className="col-6">
              <select
                className="form-select"
                onChange={(e) => search(e.target.value)}
              >
                <option value="">ทั้งหมด</option>
                <option value="11">วิ่งบน</option>
                <option value="12">วิ่งล่าง</option>
                <option value="21">2 ตัวบน</option>
                <option value="22">2 ตัวล่าง</option>
                <option value="31">3 ตัวตรง</option>
                <option value="32">3 ตัวโต๊ด</option>
                <option value="33">3 ตัวล่าง</option>
              </select>
            </div>
            <div className="col-6 text-end">
              <button
                onClick={() => setProcess(false)}
                className="btn btn-outline-warning mb-3"
              >
                คำนวณผลใหม่
              </button>
            </div>
          </div>
          <div className="table-responsive">
            <h1 className="fs-5">ผลรวม</h1>
            <table className="table table-bordered table-responsive">
              <tbody>
                {total && total.t11 > 0 && (
                  <tr>
                    <td className="text-end">วิ่งบน</td>
                    <td className="text-end">
                      {total &&
                        currencyFormat({
                          number: total.t11,
                          decimal: 0,
                        })}
                    </td>
                  </tr>
                )}

                {total && total.t12 > 0 && (
                  <tr>
                    <td className="text-end">วิ่งล่าง</td>
                    <td className="text-end">
                      {total &&
                        currencyFormat({
                          number: total.t12,
                          decimal: 0,
                        })}
                    </td>
                  </tr>
                )}
                {total && total.t21 > 0 && (
                  <tr>
                    <td className="text-end">2 ตัวบน</td>
                    <td className="text-end">
                      {total &&
                        currencyFormat({
                          number: total.t21,
                          decimal: 0,
                        })}
                    </td>
                  </tr>
                )}
                {total && total.t22 > 0 && (
                  <tr>
                    <td className="text-end">2 ตัวล่าง</td>
                    <td className="text-end">
                      {total &&
                        currencyFormat({
                          number: total.t22,
                          decimal: 0,
                        })}
                    </td>
                  </tr>
                )}

                {total && total.t31 > 0 && (
                  <tr>
                    <td className="text-end">3 ตัวตรง</td>
                    <td className="text-end">
                      {total &&
                        currencyFormat({
                          number: total.t31,
                          decimal: 0,
                        })}
                    </td>
                  </tr>
                )}
                {total && total.t32 > 0 && (
                  <tr>
                    <td className="text-end">3 ตัวโต๊ด</td>
                    <td className="text-end">
                      {total &&
                        currencyFormat({
                          number: total.t32,
                          decimal: 0,
                        })}
                    </td>
                  </tr>
                )}
                {total && total.t33 > 0 && (
                  <tr>
                    <td className="text-end">3 ตัวล่าง</td>
                    <td className="text-end">
                      {total &&
                        currencyFormat({
                          number: total.t33,
                          decimal: 0,
                        })}
                    </td>
                  </tr>
                )}
                {total && total.all > 0 && (
                  <tr>
                    <td className="text-end fw-bold">รวมทั้งหมด</td>
                    <td className="text-end fw-bold">
                      {total &&
                        currencyFormat({
                          number: total.all,
                          decimal: 0,
                        })}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="table-responsive">
            <h1 className="fs-5">รายละเอียด</h1>
            <table className="table table-responsive table-bordered">
              <thead>
                <tr>
                  <th className="text-center fw-bold">บิลที่</th>
                  <th className="text-center fw-bold">ลูกค้า</th>
                  <th className="text-center fw-bold">หมายเลข</th>
                  <th className="text-center fw-bold">จำนวนเงิน</th>
                  <th className="text-center fw-bold">เรทจ่าย</th>
                  <th className="text-center fw-bold">รางวัล</th>
                  <th className="text-center fw-bold">ประเภทหวย</th>
                </tr>
              </thead>
              <tbody>
                {results &&
                  results.length > 0 &&
                  results.map((r, index) => (
                    <tr key={index}>
                      <td className="text-end">{r.bill}</td>
                      <td>{r.customer}</td>
                      <td className="text-end">{r.number}</td>
                      <td className="text-end">
                        <b>{r.price}</b>
                      </td>
                      <td className="text-end">{r["rate"]}</td>
                      <td className="text-end">{r["reward"]}</td>
                      <td className="text-center">{renderType(r.ntype)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Result;
