import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, TOKEN } from "../config";
import customerService from "../services/customer";
export const getCustomers = createAsyncThunk(
  "customers/getCustomers",
  async ({ search }) => {
    try {
      let res = await customerService.find({ search });
      if (res.data.status == "ok") {
        return res.data.data;
      } else {
        return [];
      }
    } catch (error) {
      return error;
    }
  }
);
export const getCustomerByid = createAsyncThunk(
  "customers/getCustomerByid",
  async ({ id }) => {
    try {
      let res = await customerService.findById({ id });
      if (res.data.status == "ok") {
        return res.data.data;
      } else {
        return null;
      }
    } catch (error) {
      return error;
    }
  }
);
export const createCustomer = createAsyncThunk(
  "customers/createCustomer",
  async (formData) => {
    try {
      let res = await customerService.create(formData);
      return res.data;
    } catch (error) {
      return error;
    }
  }
);

export const reportCustomers = createAsyncThunk(
  "customers/reportCustomers",
  async ({ search }) => {
    try {
      let res = await customerService.report({ search });
      if (res.data.status == "ok") {
        return res.data;
      } else {
        return [];
      }
    } catch (error) {
      return error;
    }
  }
);
const customerSlice = createSlice({
  name: "customers",
  initialState: {
    customers: [],
    customer: null,
    results: [],
    loading: false,
    error: null,
    statusChange: false,
  },
  extraReducers: {
    //loading
    [getCustomers.pending]: (state) => {
      state.loading = true;
    },
    //success
    [getCustomers.fulfilled]: (state, action) => {
      state.loading = false;
      state.customers = action.payload;
    },
    //error
    [getCustomers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //loading
    [getCustomerByid.pending]: (state) => {
      state.loading = true;
    },
    //success
    [getCustomerByid.fulfilled]: (state, action) => {
      state.loading = false;
      state.customer = action.payload;
    },
    //error
    [getCustomerByid.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //create
    [createCustomer.pending]: (state) => {
      state.loading = true;
      state.statusChange = false;
    },
    [createCustomer.fulfilled]: (state, action) => {
      state.loading = false;
      state.statusChange = true;
    },
    [createCustomer.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.statusChange = false;
    },

    //loading
    [reportCustomers.pending]: (state) => {
      state.loading = true;
    },
    //success
    [reportCustomers.fulfilled]: (state, action) => {
      state.loading = false;
      state.results = action.payload;
    },
    //error
    [reportCustomers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export default customerSlice.reducer;
