import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reportCustomers } from "../../redux/customerSlice";
import { useDownloadExcel } from "react-export-table-to-excel";
import { convertDateTimeTh, currencyFormat } from "../../config";

const CustomerReport = () => {
  const dispatch = useDispatch();
  const { results } = useSelector((state) => state.customers);
  const [search, setSearch] = useState("");
  const tableRef = useRef(null);
  const [date, setDate] = useState("");

  useEffect(() => {
    let createDate = localStorage.getItem("createDate");
    if (createDate) {
      // setDate(createDate);
      setDate(convertDateTimeTh(createDate));
    }
    dispatch(reportCustomers({ search: "" }));
  }, []);

  const searchFund = () => {
    dispatch(reportCustomers({ search }));
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "รายงานสรุป",
    sheet: "รายงานสรุป",
  });

  return (
    <div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-4 col-12">
            <input
              type={"text"}
              placeholder={"ค้นหาลูกค้า"}
              onChange={(e) => setSearch(e.target.value)}
              className="form-control"
              // style={{ height: "35px" }}
            />
          </div>
          <div className="col-md-4 col-12 pt-2">
            <button
              className="btn btn-primary rounded-2"
              onClick={() => searchFund()}
              style={{ marginTop: "-5px" }}
            >
              <i className="fa fa-search"></i> ค้นหา
            </button>
            <button
              className="btn btn-success rounded-2 ms-2"
              onClick={() => onDownload()}
              style={{ marginTop: "-5px" }}
            >
              <i className="fa fa-file-excel-o"></i> ส่งออก
            </button>
          </div>
        </div>
        <div className="table-responsive mt-3">
          <table className="table table-bordered" ref={tableRef}>
            <thead>
              <tr>
                <th colSpan={12} className="text-white">
                  รายงานสรุปรายได้ งวดวันที่ {date}
                </th>
              </tr>
              <tr>
                <th>ลูกค้า</th>
                <th>กำไร</th>
                <th>ยอดถูก</th>
                <th>ยอดขายรวม</th>
                <th>เปอร์เซ็นต์</th>
                <th>วิ่งบน</th>
                <th>วิ่งล่าง</th>
                <th>2ตัวบน</th>
                <th>2ตัวล่าง</th>
                <th>3ตัวบน</th>
                <th>3ตัวโต๊ด</th>
                <th>3ตัวล่าง</th>
              </tr>

              {results && results.total && (
                <tr>
                  <th className="th-total">รวม</th>
                  <th className="th-total text-end">{results.total.profit}</th>
                  <th className="th-total text-end">{results.total.reward}</th>
                  <th className="text-right th-total">
                    {results.total.total1}
                  </th>
                  <th className="text-right th-total">
                    {results.total.total2}
                  </th>
                  <th className="text-right th-total">
                    {results.total.price11}
                  </th>
                  <th className="text-right th-total">
                    {results.total.price12}
                  </th>
                  <th className="text-right th-total">
                    {results.total.price21}
                  </th>
                  <th className="text-right th-total">
                    {results.total.price22}
                  </th>
                  <th className="text-right th-total">
                    {results.total.price31}
                  </th>
                  <th className="text-right th-total">
                    {results.total.price33}
                  </th>
                  <th className="text-right th-total">
                    {results.total.price32}
                  </th>
                </tr>
              )}
            </thead>
            <tbody>
              {results &&
                results.data &&
                results.data.map((res, index) => (
                  <tr key={index}>
                    <td>
                      {(index += 1)}. {res.cusName}
                    </td>
                    <th className="text-right text-white fw-bold">
                      {res["profit"]}
                    </th>
                    <td className="text-right fw-bold">
                      {res["reward"]
                        ? currencyFormat({
                            number: parseFloat(res["reward"]),
                            decimal: 0,
                          })
                        : 0}
                    </td>
                    <td className="text-right fw-bold">
                      {res.totalPrice || ""}
                    </td>
                    <td className="text-right fw-bold">
                      {res.totalPercent || ""}
                    </td>
                    <td className="text-right">{res.price11 || ""}</td>
                    <td className="text-right">{res.price12 || ""}</td>
                    <td className="text-right">{res.price21 || ""}</td>
                    <td className="text-right">{res.price22 || ""}</td>
                    <td className="text-right">{res.price31 || ""}</td>
                    <td className="text-right">{res.price33 || ""}</td>
                    <td className="text-right">{res.price32 || ""}</td>
                  </tr>
                ))}
            </tbody>
            <tfoot>
              {results && results.total && (
                <tr>
                  <th className="th-total">รวม</th>
                  <th className="text-right th-total">
                    {results.total.profit}
                  </th>
                  <th className="text-right th-total">
                    {results.total.reward}
                  </th>
                  <th className="text-right th-total">
                    {results.total.total1}
                  </th>
                  <th className="text-right th-total">
                    {results.total.total2}
                  </th>
                  <th className="text-right th-total">
                    {results.total.price11}
                  </th>
                  <th className="text-right th-total">
                    {results.total.price12}
                  </th>
                  <th className="text-right th-total">
                    {results.total.price21}
                  </th>
                  <th className="text-right th-total">
                    {results.total.price22}
                  </th>
                  <th className="text-right th-total">
                    {results.total.price31}
                  </th>
                  <th className="text-right th-total">
                    {results.total.price33}
                  </th>
                  <th className="text-right th-total">
                    {results.total.price32}
                  </th>
                </tr>
              )}
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CustomerReport;
