import React, { useEffect } from "react";
import Layout from "../../components/v2/Layout";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { getNumbers } from "../../redux/numberSlice";
import ListNumber from "../../components/v2/ListNumber";

const Bill = () => {
  const { numbers } = useSelector((state) => state.numbers);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNumbers({ search: "" }));
  }, []);
  const handleSearch = (value) => {
    dispatch(getNumbers({ search: value }));
  };
  return (
    <Layout>
      <h4>บิลทั้งหมด</h4>
      <div>
        {numbers ? (
          <ListNumber handleSearch={handleSearch} numbers={numbers} />
        ) : (
          <div>ไม่พบข้อมูล</div>
        )}
      </div>
    </Layout>
  );
};

export default Bill;
