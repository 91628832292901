import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
const Header = ({ goBack }) => {
  const [displayName, setDisplayName] = useState("");
  const [token, setToken] = useState("");
  const [createDate, setCreateDate] = useState("");
  //init data
  useEffect(() => {
    let name = localStorage.getItem("name");
    let token = localStorage.getItem("token");
    let date = localStorage.getItem("createDate");
    if (date) {
      setCreateDate(moment(date).format("DD/MM/YYYY"));
    }
    if (name) {
      setDisplayName(name);
    }
    if (token) {
      setToken(token);
    }
  });
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <Link
            to={goBack ? goBack : "/"}
            className="nav-brand text-white"
            aria-current="page"
            style={{ fontWeight: "bold" }}
          >
            <i className="fa fa-angle-double-left"></i> เมนูหลัก งวดวันที่:{" "}
            {createDate}
          </Link>
          <div className="collapse navbar-collapse" id="navbarText"></div>
        </div>
      </nav>

      {/* <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link
            to="/home"
            className="nav-link "
            aria-current="page"
            style={{ fontWeight: "bold" }}
          >
            LOTTO
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarScroll">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li>
                <Link
                  to="/logout"
                  className="dropdown-item"
                  aria-current="page"
                >
                  Logout
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarScrollingDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {displayName}
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarScrollingDropdown"
                >
                  <li>
                    <Link
                      to="/logout"
                      className="dropdown-item"
                      aria-current="page"
                    >
                      Logout
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav> */}
    </>
  );
};

export default Header;
