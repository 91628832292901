import { configureStore } from "@reduxjs/toolkit";
import numberSlice from "./numberSlice";
import customerSlice from "./customerSlice";
import report1Slice from "./report1Slice";
import report2Slice from "./report2Slice";
import priceLimitSlice from "./priceLimitSlice";
export default configureStore({
  reducer: {
    numbers: numberSlice,
    customers: customerSlice,
    report1: report1Slice,
    report2: report2Slice,
    priceLimit: priceLimitSlice,
  },
  devTools: true,
});
