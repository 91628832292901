import React, { Fragment } from "react";

const TableComponent = ({ datas, type, min, max, breakPage, total, label }) => {
  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th style={{ textAlign: "center" }}>{label}</th>
            <th style={{ textAlign: "center" }}>ราคา</th>
          </tr>
        </thead>
        <tbody>
          {datas.length > 0 &&
            datas.map((i, index) => (
              <Fragment key={index}>
                {i.type === type && (
                  <tr key={index}>
                    <td style={{ textAlign: "right" }}>{i.number}</td>
                    <td style={{ textAlign: "right" }}>{i.price}</td>
                  </tr>
                )}
              </Fragment>
            ))}
        </tbody>
      </table>
      <table className="table table-bordered" style={{ marginTop: "-18px" }}>
        {total !== "" && (
          <tfoot>
            <tr>
              <td style={{ textAlign: "right" }}>รวมทั้งหมด</td>
              <td style={{ textAlign: "right" }}>{total}</td>
            </tr>
          </tfoot>
        )}
      </table>
      {breakPage === true && <div className="page-break"></div>}
    </div>
  );
};

export default TableComponent;
