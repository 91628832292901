import React from "react";

const InforSell = () => {
  return (
    <div>
      <p>
        <a
          style={{ color: "white", cursor: "pointer" }}
          data-bs-toggle="collapse"
          data-bs-target="#collapseWidthExample"
          aria-expanded="false"
          aria-controls="collapseWidthExample"
          className="text-warning"
        >
          * วิธีการกรอกข้อมูล{" "}
          <i className="fa fa-hand-o-right" aria-hidden="true"></i> คลิ๊ก{" "}
          <i className="fa fa-hand-o-left" aria-hidden="true"></i>
        </a>
      </p>
      <div>
        <div className="collapse collapse-horizontal" id="collapseWidthExample">
          <div className="alert alert-info mt-3">
            <div>วิธีการกรอกข้อมูล</div>
            <div>
              <small><span className="fw-bold">1=100*50</span> หมายถึง เลขวิ่ง บน=100 ล่าง=50</small>
            </div>
            <div>
              <small><span className="fw-bold">21=100*50</span> หมายถึง เลข2ตัว บน=100 ล่าง=50</small>
            </div>
            <div>
              <small>
              <span className="fw-bold">193=100*50*60</span> หมายถึง เลข3ตัว บน=100 โต๊ด=50 ล่าง=60
              </small>
            </div>
            <div>
              <small>
                <span className="fw-bold">22 33 44=100*50</span> หมายถึง เลข2ตัว บน=100 ล่าง=50
              </small>
            </div>
            <div>
              <small>
                <span className="fw-bold">123 23=100*50</span> หมายถึง เลข2ตัว บน=100 ล่าง=50 เลข3ตัวบน=100 โต๊ด=50
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InforSell;
