import React, { useEffect } from "react";
import Layout from "../../components/v2/Layout";
import { useForm } from "react-hook-form";
import { useAlert } from "react-alert";
import TextInput from "../../components/v2/TextInput";
import { createCustomer, getCustomerByid } from "../../redux/customerSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
const FormCustomer = () => {
  const { customer } = useSelector((state) => state.customers);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const alert = useAlert();
  let params = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("num1", 10);
    setValue("num2", 25);
    setValue("num3", 25);
    setValue("num4", 25);
    setValue("num5", 25);

    setValue("pay1", 300);
    setValue("pay2", 400);
    setValue("pay3", 70);
    setValue("pay4", 500);
    setValue("pay5", 95);
    setValue("pay6", 95);
  }, []);
  //submit

  useEffect(() => {
    if (params.id) {
      dispatch(getCustomerByid({ id: params.id }));
    }
  }, [params.id]);

  console.log(customer);

  useEffect(() => {
    if (customer) {
      setValue("id", customer.id);
      setValue("cusName", customer.cusName);
      setValue("num1", customer.num1);
      setValue("num2", customer.num2);
      setValue("num3", customer.num3);
      setValue("num4", customer.num4);
      setValue("num5", customer.num5);

      setValue("pay1", customer.pay1);
      setValue("pay2", customer.pay2);
      setValue("pay3", customer.pay3);
      setValue("pay4", customer.pay4);
      setValue("pay5", customer.pay5);
      setValue("pay6", customer.pay6);
    }
  }, [customer]);
  const onSubmit = (data) => {
    let formData = new FormData();
    for (let i of Object.keys(data)) {
      formData.append(i, data[i]);
    }
    dispatch(createCustomer(formData))
      .unwrap()
      .then(() => {
        alert.success("เพิ่มข้อมูลสำเร็จ");
        navigate("/customer");
      })
      .catch((error) => console.log(error));
  };
  return (
    <Layout goBack='/customer'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h4>กำหนด % ลูกค้า</h4>

        <TextInput
          label="ID"
          register={register}
          name="id"
          errors={errors}
          placeholder=""
          type="hidden"
          hiddenLabels={true}
        />

        <div className="row">
          <div className="col-md-2 mt-2">
            {" "}
            <TextInput
              label="ชื่อลูกค้า"
              register={register}
              name="cusName"
              errors={errors}
              placeholder=""
              type="text"
              required="required"
            />
          </div>
          <div className="col-md-2 mt-2">
            <TextInput
              label="% เลขวิ่ง"
              register={register}
              name="num1"
              errors={errors}
              placeholder=""
              type="text"
              required="required"
            />
          </div>
          <div className="col-md-2 mt-2">
            <TextInput
              label="% เลข 2 ตัว"
              register={register}
              name="num2"
              errors={errors}
              placeholder=""
              type="text"
              required="required"
            />
          </div>
          <div className="col-md-2 mt-2">
            <TextInput
              label="% เลข 3 ตัวตรง"
              register={register}
              name="num3"
              errors={errors}
              placeholder=""
              type="text"
              required="required"
            />
          </div>
          <div className="col-md-2 mt-2">
            <TextInput
              label="% เลข 3 ตัวโต๊ต"
              register={register}
              name="num4"
              errors={errors}
              placeholder=""
              type="text"
              required="required"
            />
          </div>
          <div className="col-md-2 mt-2">
            <TextInput
              label="% เลข 3 ล่าง"
              register={register}
              name="num5"
              errors={errors}
              placeholder=""
              type="text"
              required="required"
            />
          </div>
        </div>
        <h4 className="mt-5">อัตราการจ่ายเงิน</h4>
        <div className="row">
          <div className="col-md-2 mt-2">
            {" "}
            <TextInput
              label="วิ่งบน"
              register={register}
              name="pay1"
              errors={errors}
              placeholder=""
              type="text"
              required="required"
            />
          </div>
          <div className="col-md-2 mt-2">
            <TextInput
              label="วิ่งล่าง"
              register={register}
              name="pay2"
              errors={errors}
              placeholder=""
              type="text"
              required="required"
            />
          </div>
          <div className="col-md-2 mt-2">
            <TextInput
              label="2ตัว บน-ล่าง"
              register={register}
              name="pay3"
              errors={errors}
              placeholder=""
              type="text"
              required="required"
            />
          </div>
          <div className="col-md-2 mt-2">
            <TextInput
              label=" 3 ตัวตรง"
              register={register}
              name="pay4"
              errors={errors}
              placeholder=""
              type="text"
              required="required"
            />
          </div>
          <div className="col-md-2 mt-2">
            <TextInput
              label="3 ตัวโต๊ต"
              register={register}
              name="pay5"
              errors={errors}
              placeholder=""
              type="text"
              required="required"
            />
          </div>
          <div className="col-md-2 mt-2">
            <TextInput
              label="3 ตัวล่าง"
              register={register}
              name="pay6"
              errors={errors}
              placeholder=""
              type="text"
              required="required"
            />
          </div>
        </div>

        <div className="row mt-3 mb-5 ">
          <div className="col-md-3">
            <Link to={`/customer`} className="btn btn-secondary">
              {`<<`} ยกเลิก
            </Link>{" "}
            <button type="submit" className="btn btn-primary">
              บันทึก {`>>`}
            </button>
          </div>{" "}
        </div>
      </form>
    </Layout>
  );
};

export default FormCustomer;
