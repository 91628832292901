import React from "react";
import { Link } from "react-router-dom";
const Menu = ({ title, link, style, icon, className }) => {
  return (
    <Link to={`${link}`} className="card mt-2 text-white shadow">
      <div className={`card-body ${className}`} style={style}>
        <div className="row">
          <div className="col-12 text-center">
            {icon ? <img src={icon} alt="" className="img img-fluid" /> : ""}

            <div style={{ fontSize: "14px" }}>
              <b>{title}</b>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Menu;
