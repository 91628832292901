import React from "react";
import Header from "./Header";
const Layout = ({ children, goBack }) => {
  return (
    <div>
      <Header goBack={goBack} />
      <div className="container mt-1">{children}</div>
    </div>
  );
};

export default Layout;
